import { useState, useEffect, useCallback } from 'react'
import Loading from '../../Assets/Images/loading.svg'
import { dateFormatting_Y, dateFormatting_YMD, slider } from '../../Helpers/utils'

const safeSearch = localStorage.getItem('safe-search')

export default function HomePageList(props) {
    const [results, setResults] = useState([]);

    const fetchResults = useCallback(async () => {
        const response = await fetch(`https://api.skuflic.net/${props.type}?include_adult=${safeSearch}&page=1&with_genres=${props.genre}&with_networks=${props.network}&language=en&include_image_language=en`, { cache: 'force-cache' });
        const data = await response.json();
        setResults(data);
    }, [props.type, props.genre, props.network]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    if (props.cardType === 'short') {
        return results?.results?.length ? (
            <>
                <div className='homepage-card'>
                    <h3 className='trending-title'>{props.title}</h3>
                    <p className='trending-subtitle'>{props.subtitle}</p>
                    <div className='main-item-holder home-page'>
                        <div style={{ display: 'flex' }}>
                            <div className='scroll-tray  special-padding' id={props.id}>
                                <button id='slide' type='button' style={{ left: '0' }} className='arrow-buttons' onClick={() => slider(props.id, 'left')} alt='Scroll Left'><i className='arrow left'></i></button>
                                {results.results?.map(results =>
                                    <a href={`/${props.link}/${results.id}`} key={results.id}>
                                        <div className='holder'>
                                            <div className='gradient'></div>

                                            {(results.profile_path === null || results.poster_path === null) ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className={props.class} src={`https://image.tmdb.org/t/p/w780${results.profile_path ?? results.poster_path}`} alt={results.title ?? results.name} style={{ maxWidth: '200px' }} />}

                                            <div className='overlay'>
                                                <div className='subtitle'>
                                                    {!results.vote_average ? '' : `${results?.vote_average?.toFixed(1)}/10 — `}
                                                    {!results?.release_date ? '' : `${new Date(results?.release_date) < new Date() ? new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                    {!results?.first_air_date ? '' : `${new Date(results?.first_air_date) < new Date() ? new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                </div>
                                                <div className='title'>{results.title ?? results.name}</div>
                                            </div>
                                        </div>
                                    </a>
                                )}
                                <button id='slide' type='button' style={{ right: '0' }} className='arrow-buttons' onClick={() => slider(props.id, 'right')} alt='Scroll Right'><i className='arrow right'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : <><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else {
        return results?.results?.length ? (
            <>
                <div className='homepage-card'>
                    <h3 className='trending-title'>{props.title}</h3>
                    <p className='trending-subtitle'>{props.subtitle}</p>
                    <div className='main-item-holder home-page'>
                        <div style={{ display: 'flex' }}>
                            <div className='scroll-tray  special-padding' id={props.id}>
                                <button id='slide' type='button' style={{ left: '0' }} className='arrow-buttons' onClick={() => slider(props.id, 'left')} alt='Scroll Left'><i className='arrow left'></i></button>
                                {results.results?.map(results =>
                                    <a href={`/${props.link}/${results.id}`} key={results.id}>
                                        <div className='holder horizontal'>
                                            <div className='gradient'></div>

                                            {(results.profile_path === null || results.backdrop_path === null) ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className={props.class} src={`https://image.tmdb.org/t/p/w1280${results.profile_path ?? results.backdrop_path}`} alt={results.title ?? results.name} />}

                                            <div className='overlay'>
                                                <div className='subtitle'>
                                                    {!results.vote_average ? '' : `${results?.vote_average?.toFixed(1)}/10 — `}
                                                    {!results?.release_date ? '' : `${new Date(results?.release_date) < new Date() ? new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                    {!results?.first_air_date ? '' : `${new Date(results?.first_air_date) < new Date() ? new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                </div>
                                                <div className='title longer'>{results.title ?? results.name}</div>
                                            </div>
                                        </div>
                                    </a>
                                )}
                                <button id='slide' type='button' style={{ right: '0' }} className='arrow-buttons' onClick={() => slider(props.id, 'right')} alt='Scroll Right'><i className='arrow right'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : <><div className='loading'><img src={Loading} alt='Loading' /></div></>
    }
}