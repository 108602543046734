import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { CustomNavbar, Skeleton } from '../../Components/index';
import { slider, dateFormatting_Y, dateFormatting_YMD } from '../../Helpers/utils';
import pluralize from 'pluralize';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function TrendingList(props) {
    const [results, setResults] = useState([]);
    const [active, setActive] = useState('');
    const [totalPages, setTotalPages] = useState(0); // State to store total pages
    const safeSearch = localStorage.getItem('safe-search');
    const query = useQuery().get('sort_by');
    const [loading, setLoading] = useState(true);

    // Cache for storing previous API responses
    const cache = useRef({});

    // Memoized fetchData function
    const fetchData = useCallback(async (page = 1) => {
        const url = `https://api.skuflic.net/${props.type}?include_adult=${safeSearch}&with_genres=${props.genre}&with_networks=${props.network}&sort_by=${query}&include_image_language=en,null&page=${page}`;

        // Check if data is cached
        if (cache.current[url]) {
            setResults(cache.current[url]);
            setActive(page);
            setLoading(false);
        } else {
            try {
                setLoading(true);
                const response = await fetch(url);
                const data = await response.json();
                cache.current[url] = data; // Cache the response
                setResults(data);
                setTotalPages(Math.min(data.total_pages, 500)); // Update total pages state with a maximum of 500
                setActive(page);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    }, [props.type, props.genre, props.network, safeSearch, query]);

    useEffect(() => {
        fetchData(); // Initial data fetch
    }, [fetchData]);

    // Debounced handleSubmit function to handle pagination
    const debouncedHandleSubmit = useCallback(
        debounce(async (pageNumber) => {
            if (pageNumber > 0 && pageNumber <= totalPages) {
                fetchData(pageNumber);
            } else {
                console.error('Page number out of bounds');
            }
        }, 300), // Debounce delay in milliseconds
        [fetchData, totalPages]
    );

    // Handle form submission (pagination)
    const handleSubmit = (e) => {
        e.preventDefault();
        const pageNumber = Number(e.target.value);
        debouncedHandleSubmit(pageNumber);
    };

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    const indicatorArray = [...Array(totalPages).keys()].map(i => i + 1);
    return (
        document.title = `${props.name} - Reel`,
        <div className='main'>
            <CustomNavbar />
            <div className='custom-container content'>
                <h3>{props.name}</h3>
                <p>If you're not satisfied with these options, feel free to use our search {props.genre && 'or sort'} function.</p>
                {!props.genre || props.type === 'discover/tv' ? '' :
                    <div className='scroll-tray' style={{ paddingTop: '1px', paddingBottom: '20px' }}>
                        <a href='?sort_by=original_title.asc' className={query?.includes('original_title.asc') ? 'sort active' : 'sort'}>Title (A-Z)</a>
                        <a href='?sort_by=original_title.desc' className={query?.includes('original_title.desc') ? 'sort active' : 'sort'}>Title (Z-A)</a>
                        <a href='?sort_by=vote_average.desc' className={query?.includes('vote_average.desc') ? 'sort active' : 'sort'}>Best Rated</a>
                        <a href='?sort_by=vote_average.asc' className={query?.includes('vote_average.asc') ? 'sort active' : 'sort'}>Lowest Rated</a>
                        <a href='?sort_by=primary_release_date.desc' className={query?.includes('primary_release_date.desc') ? 'sort active' : 'sort'}>Latest</a>
                        <a href='?sort_by=primary_release_date.asc' className={query?.includes('primary_release_date.asc') ? 'sort active' : 'sort'}>Oldest</a>
                    </div>
                }

                {!props.genre || props.type === 'discover/movie' ? '' :
                    <div className='scroll-tray' style={{ paddingTop: '1px', paddingBottom: '20px' }}>
                        <a href='?sort_by=vote_average.desc' className={query?.includes('vote_average.desc') ? 'sort active' : 'sort'}>Best Rated</a>
                        <a href='?sort_by=vote_average.asc' className={query?.includes('vote_average.asc') ? 'sort active' : 'sort'}>Lowest Rated</a>
                        <a href='?sort_by=first_air_date.desc' className={query?.includes('first_air_date.desc') ? 'sort active' : 'sort'}>Latest</a>
                        <a href='?sort_by=first_air_date.asc' className={query?.includes('first_air_date.asc') ? 'sort active' : 'sort'}>Oldest</a>
                    </div>
                }
                <div className='scroll-tray'>
                    {!inView && (
                        <button
                            id='slide'
                            type='button'
                            className='arrow-buttons arrow-left'
                            onClick={() => slider('scroll-tray-content', 'left')}
                        >
                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                        </button>
                    )}

                    <div className='scroll-tray' id='scroll-tray-content'>
                        {loading ? (
                            Array.from({ length: 10 }).map((_, index) => (
                                <Skeleton key={index} />
                            ))
                        ) : (
                            results?.results?.filter((results) => results.poster_path != null || results.profile_path != null).map(results => (
                                <a href={`/${props.link}/${results.id}`} key={results.id} ref={ref}>
                                    <div className='holder'>
                                        <div className='gradient'></div>
                                        {(results.profile_path === null || results.poster_path === null) ? (
                                            <span className='material-symbols-outlined image not-available'> hide_image </span>
                                        ) : (
                                            <img className='image' src={`https://image.tmdb.org/t/p/w780${results.poster_path ?? results.profile_path}`} alt={results.title ?? results.name} />
                                        )}
                                        <div className='overlay'>
                                            <ul className='subtitle content-short-overview'>
                                                {!!results.vote_average && <li>{results.vote_average?.toFixed(1)}/10</li>}
                                                {results?.release_date &&
                                                    <li>
                                                        {new Date(results.release_date) < new Date() ?
                                                            new Date(results.release_date).toLocaleDateString('en-US', dateFormatting_Y) :
                                                            `Releases ${new Date(results.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}
                                                    </li>
                                                }
                                            </ul>
                                            <div className='title'>{results.title ?? results.name}</div>
                                        </div>
                                    </div>
                                </a>
                            ))
                        )}
                    </div>

                    {!inView && (
                        <button
                            id='slide'
                            type='button'
                            className='arrow-buttons arrow-right'
                            onClick={() => slider('scroll-tray-content', 'right')}
                        >
                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                        </button>
                    )}
                </div>
            </div>

            {!results?.results ? '' :
                <div className='results-indicator'>
                    <p>A total of {results.total_results} {pluralize('result', results.total_results)} has been found.<br />Remember to check your <a href='/safesearch'>SafeSearch</a> settings.</p>
                    {!results.total_results ? '' :
                        <div className='scroll-tray main'>
                            <button type='button' className='main-button' onClick={() => slider('scroll-tray-indicator', 'left')}>
                                <span className='material-symbols-outlined'>
                                    keyboard_arrow_left
                                </span>
                            </button>

                            <div className='scroll-tray custom-holder-slider' id='scroll-tray-indicator'>
                                {indicatorArray?.map(page_indicator =>
                                    <button className={active == page_indicator ? 'indicator active' : 'indicator'} id={page_indicator} value={page_indicator} key={page_indicator} onClick={handleSubmit}>{page_indicator}</button>
                                )}
                            </div>

                            <button type='button' className='main-button' onClick={() => slider('scroll-tray-indicator', 'right')}>
                                <span className='material-symbols-outlined'>
                                    keyboard_arrow_right
                                </span>
                            </button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
