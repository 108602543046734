// React and other libraries
import { useState, useEffect, useCallback } from 'react';
import { ContentModal } from '../index'
import { fetchWithCache } from '../../Helpers/utils';

export default function AnimatedList(props) {
    const [results, setResults] = useState([]);
    const [modal, setModal] = useState({ show: false, data: null });

    const fetchResults = useCallback(async () => {
        try {
            const safeSearch = localStorage.getItem('safe-search') || 'false';
            const url = `https://api.skuflic.net/${props.type}?include_adult=${safeSearch}&page=1&with_genres=${props.genre}&with_networks=${props.network}&language=en-US&sort_by=vote_average.desc&append_to_response=videos,similar,credits,recommendations,images,release_dates,releases&include_image_language=en,null`;
            const cacheKey = `${props.type}-${props.genre}-${props.network}`;

            const data = await fetchWithCache(url, cacheKey);
            setResults(data);
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    }, [props.type, props.genre, props.network]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    const handleClose = () => {
        setModal({ show: false, data: null });
    };

    const open = (data) => {
        setModal({ show: true, data: data });
    };

    return (
        <div className='slider scroll-tray'>
            <div className='slide-track' style={{ animationDuration: `${props.speed}s` }}>
                {results.results?.filter((backdrop_path) => backdrop_path.poster_path != null).map(data =>
                    <div className='slide' key={results.id} onClick={() => open(data)}>
                        <span className='holder'>
                            <div className='cover'>
                                <span className='cta'>Quick View</span>
                            </div>
                            <img src={`https://image.tmdb.org/t/p/w780${data.backdrop_path}`} alt={data.name ?? data.title} />
                        </span>
                    </div>
                )}
            </div>
            {modal.show && modal.data && <ContentModal closeModal={handleClose} data={modal.data} url={props.link} />}
        </div>
    )
}