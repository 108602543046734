import { Rating } from 'react-simple-star-rating'
import { successNotification } from '../../Helpers/utils'

export default function Rate(props) {
    const session = localStorage.getItem('session');

    const handleRating = (rate) => {
        const ratingValue = { value: `${rate * 2}` };

        fetch(`https://api.skuflic.net/${props.type}/${props.id}/rating?guest_session_id=${session}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ratingValue),
        })
            .then(() => {
                successNotification(`Thank you for rating ${props.name}.`);
            })
            .catch(error => {
                throw error;
            });
    };

    return (
        <Rating allowFraction onClick={handleRating} transition fillColor={'#02FF7F'} initialValue={props.initialValue} readonly={props.readonly} customIcons={[
            {
                icon: <span className='material-symbols-outlined'> sentiment_very_dissatisfied </span>
            },
            {
                icon: <span className='material-symbols-outlined'> sentiment_dissatisfied </span>
            },
            {
                icon: <span className='material-symbols-outlined'> sentiment_neutral </span>
            },
            {
                icon: <span className='material-symbols-outlined'> sentiment_satisfied </span>
            },
            {
                icon: <span className='material-symbols-outlined'> sentiment_very_satisfied </span>
            }
        ]} />
    )
}