import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'

// Custom Functions
import { CustomNavbar, Rate, Reviews, Share, Skeleton } from '../../index.js'
import { listOfCountries, slider, dateFormatting_Y, dateFormatting_YMD, time_convert, calculateNew, number_formatter } from '../../../Helpers/utils.js'

// Firebase
import { auth } from '../../../firebase'

// Custom Imports
import Loading from '../../../Assets/Images/loading.svg'
import Image from '../../Image/Image'
import { NotFound } from '../../../index.js'

// Ligthbox
import FsLightbox from 'fslightbox-react'
import pluralize from 'pluralize'
import useFavoritesStore from '../../../Store/useFavoritesStore.jsx';
import { onAuthStateChanged } from 'firebase/auth';

export default function TVShowDetails() {
    const { tvID } = useParams()

    // State management for content
    const [tv, setTVShow] = useState(null)
    const [isLoaded, setLoaded] = useState(false)
    const [loadedSeasons, setLoadedSeasons] = useState(false)
    const [seasons, setSeasons] = useState([])
    const [selectedSeason, setSelectedSeason] = useState('')
    const [fetchedSeasons, setFetchedSeasons] = useState({})
    const CACHE_DURATION = 3600000
    const cache = new Map()

    // SafeSearch Settings and Country Management
    const safeSearch = localStorage.getItem('safe-search')
    let country = localStorage.getItem('detected-country')
    let local = localStorage.getItem('selected-country') ?? country

    // Trailers & Gallery
    const gallery = tv?.images?.backdrops?.map(results => `https://image.tmdb.org/t/p/original${results?.file_path}`)
    const trailers = tv?.videos?.results?.map(results => `https://www.youtube.com/watch?v=${results?.key}`)

    const [galleryToggler, setGalleryToggler] = useState(false)
    const [videoToggler, setVideoToggler] = useState(false)
    const [expandedItem, setExpandedItem] = useState(null)

    // Favorites
    const { addToFavorites, isFavorite, removeFromFavorites } = useFavoritesStore();

    const fetchInitialFavorites = useFavoritesStore((state) => state.fetchInitialFavorites);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchInitialFavorites();
            }
        });

        return () => unsubscribe();
    }, [fetchInitialFavorites]);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId))
    }

    const fetchTVShowData = useCallback(async (id, mediaType) => {
        const cacheKey = `${mediaType}-${id}`
        const cachedData = cache.get(cacheKey)
        const now = Date.now()

        if (cachedData && now - cachedData.timestamp < CACHE_DURATION) {
            return cachedData.data
        }

        try {
            // Fetch TV show details
            const tvShowUrl = `https://api.skuflic.net/tv/${tvID}?include_adult=${safeSearch}&append_to_response=videos,similar,credits,recommendations,reviews,images,release_dates,content_ratings,external_ids&include_image_language=en,null`

            // Fetch TV show details with caching
            const tvShowResponse = await fetch(tvShowUrl, {
                cache: 'force-cache',  // Force cache usage
                headers: {
                    'Cache-Control': 'max-age=3600',  // Cache for 1 hour (adjust as needed)
                },
            })
            if (!tvShowResponse.ok) {
                throw new Error('Failed to fetch TV show details')
            }
            const tvShowDetails = await tvShowResponse.json()

            // Fetch rating
            const ratingResponse = await fetch(`https://ratings.skuflic.net?id=${tvShowDetails.external_ids.imdb_id}`, {
                cache: 'force-cache',  // Force cache usage
                headers: {
                    'Cache-Control': 'max-age=3600',  // Cache for 1 hour (adjust as needed)
                },
            })
            if (!ratingResponse.ok) {
                throw new Error('Failed to fetch rating')
            }
            const dataRating = await ratingResponse.json()

            // Fetch providers
            const providersResponse = await fetch(`https://api.skuflic.net/tv/${tvID}/watch/providers`, {
                cache: 'force-cache',  // Force cache usage
                headers: {
                    'Cache-Control': 'max-age=3600',  // Cache for 1 hour (adjust as needed)
                },
            })
            if (!providersResponse.ok) {
                throw new Error('Failed to fetch providers')
            }
            const dataProviders = await providersResponse.json()

            // Update state with TV show details, rating, and providers
            setTVShow({
                ...tvShowDetails,
                rating: dataRating,
                providers: dataProviders
            })

            // Set loaded state to true
            setLoaded(true)
        } catch (error) {
            console.error('Error fetching TV show data:', error)
            // Optionally handle error state or display error message
        }
    }, [tvID, safeSearch])

    useEffect(() => {
        fetchTVShowData(tvID, 'tv')
    }, [fetchTVShowData, tvID])

    const fetchSeasons = async (season_number) => {
        setLoadedSeasons(false)
        if (fetchedSeasons[season_number]) {
            setTimeout(() => {
                setLoadedSeasons(true)
            }, '300');
            return;
        }

        try {
            const response = await fetch(`https://api.skuflic.net/tv/${tvID}/season/${season_number}?include_adult=${safeSearch}&append_to_response=videos,similar,credits,recommendations,images,release_dates,content_ratings`, {
                cache: 'force-cache',  // Force cache usage
                headers: {
                    'Cache-Control': 'max-age=3600',  // Cache for 1 hour
                },
            })
            if (!response.ok) {
                throw new Error('Failed to fetch season details')
            }
            const data = await response.json()

            // Update seasons and fetchedSeasons state
            setSeasons((prevSeasons) => ({
                ...prevSeasons,
                [season_number]: data
            }))
            setFetchedSeasons((prev) => ({ ...prev, [season_number]: true }))
        } catch (error) {
            console.error('Error fetching seasons:', error)
        } finally {
            setTimeout(() => {
                setLoadedSeasons(true)
            }, 500)
        }
    }

    const handleChange = (event) => {
        const season_number = event.target.value
        fetchSeasons(season_number)
        setSelectedSeason(season_number)
    }

    useEffect(() => {
        const fetchDefaultSeason = async () => {
            const defaultSeasonNumber = tv?.seasons?.[0]?.season_number || 1 // Default to first season if available
            await fetchSeasons(defaultSeasonNumber)
            setSelectedSeason(defaultSeasonNumber)
        }

        if (tv) {
            fetchDefaultSeason()
        }
    }, [tv])

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    })

    useEffect(() => {
        window.localStorage.setItem('selected-show', tv?.name)
    }, [tv])

    const tv_rating = tv?.content_ratings?.results?.find(o => o?.iso_3166_1 === local)
    document.title = `${!tv?.name ? `Reel` : `${tv.name} (${new Date(tv?.first_air_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`}`

    let poster = tv?.images?.backdrops.reduce((max, item) => item.vote_average > max.vote_average ? item : max, tv?.images?.backdrops[0])?.file_path
    let logo = tv?.images?.logos.reduce((max, item) => item.vote_average > max.vote_average ? item : max, tv?.images?.logos[0])?.file_path

    if (!isLoaded) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else if (!tvID) {
        return <NotFound />
    } else {
        return (
            <div>
                <Helmet>
                    <meta property='og:description' content={tv.overview} />
                    <meta property='og:image' content={`https://image.tmdb.org/t/p/w1280${tv?.backdrop_path}`} />
                    <meta property='og:site_name' content={`Reel`} />
                    <meta property='og:title' content={!tv.name ? `Reel` : `${tv.name} (${new Date(tv?.first_air_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`} />
                    <meta property='og:type' content='website' />

                    <meta name='twitter:title' content={!tv.name ? `Reel` : `${tv.name} (${new Date(tv?.first_air_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`} />
                    <meta name='twitter:description' content={tv.overview} />
                    <meta name='twitter:image' content={`https://image.tmdb.org/t/p/w1280${tv?.backdrop_path}`} />
                    <meta name='twitter:card' content={tv.overview} />
                </Helmet>

                <CustomNavbar />
                <div className='holder-container'>
                    {poster && <div className='splash'>
                        <Image title={tv.title} id='small' lowResSrc={`https://image.tmdb.org/t/p/w780${poster}`} highResSrc={`https://image.tmdb.org/t/p/original${poster}`} />
                    </div>}

                    <div className='custom-container'>
                        <div className='network'>
                            {!logo ? <h3>{tv.name} ({new Date(tv?.first_air_date)?.toLocaleDateString('en-US', dateFormatting_Y)})</h3> : <img src={`https://image.tmdb.org/t/p/w780${logo}`} alt={tv.name} />}
                        </div>

                        {tv.adult && <p className='main-button details'>This page may include potentially sensitive or age-restricted content which may be inappropriate for minors. Viewer discretion is advised.</p>}

                        <div className='buttons'>
                            <div>
                                <span
                                    className='material-symbols-outlined'
                                    onClick={isFavorite(tv) ? () => removeFromFavorites(tv) : () => addToFavorites(tv, 'tv')}
                                >
                                    {isFavorite(tv) ? 'heart_minus' : 'favorite'}
                                </span>
                                <p>{isFavorite(tv) ? 'Unfavorite' : 'Favorite'}</p>
                            </div>

                            {gallery &&
                                <div>
                                    <span className='material-symbols-outlined' onClick={() => setGalleryToggler(!galleryToggler)}>photo_library</span>
                                    <p>Gallery</p>
                                </div>
                            }

                            {trailers &&
                                <div>
                                    <span className='material-symbols-outlined' onClick={() => setVideoToggler(!videoToggler)}>animated_images</span>
                                    <p>Trailers</p>
                                </div>
                            }

                            {tv.homepage &&
                                <div>
                                    <a href={tv.homepage} target='_blank' rel='noreferrer'>
                                        <span className='material-symbols-outlined'>open_in_new</span>
                                    </a>
                                    <p>Website</p>
                                </div>
                            }
                        </div>

                        <ul className='content-short-overview'>
                            {!!tv.vote_average && <li>{tv.vote_average?.toFixed(1)}/10</li>}
                            {!!tv.genres && <li>{tv.genres?.map((genre, index) => <>{(index ? ', ' : '') + genre.name}</>)}</li>}
                        </ul>

                        <p className='details content-tagline'>{tv.tagline}</p>

                        {!!tv.overview &&
                            <div className='content-overview'>
                                <p className={expandedItem === tv.id ? 'read-less' : 'read-more' && tv.overview.length < 300 ? null : 'read-more'}>{tv.overview}</p>
                                {tv.overview.length > 300 && (
                                    <button onClick={() => handleToggle(tv.id)} className='read-more-button'>
                                        {expandedItem === tv.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        }

                        <div className='details'>

                            {!tv.vote_average || !tv?.rating?.Ratings ? '' : <>
                                <h5 className='ratings'>Audience Rating</h5>
                                <p>What do others think of {tv.name}?</p>
                            </>
                            }
                            <div className='ratings'>
                                {!!tv.vote_average &&
                                    <div>
                                        <h6 className='TheMovieDatabase'>The Movie Database</h6>
                                        <p>{tv.vote_average?.toFixed(1)}/10 ({number_formatter(tv.vote_count, 1)})</p>
                                    </div>
                                }
                                {tv?.rating?.Ratings?.map(result =>
                                    <div key={crypto.randomUUID()}>
                                        <h6 className={`${result.Source?.replace(/\s/g, '')}`}>{result.Source}</h6>
                                        <p id={`${result.Source?.replace(/\s/g, '')}`}>{result.Value}</p>
                                    </div>
                                )}
                            </div>

                            {new Date(tv?.first_air_date) >= new Date() ? null :
                                <div className='rate'>
                                    <h5>Your Rating</h5>
                                    <p>What do you think of {tv.name}?</p>
                                    <Rate type='tv' id={tv.id} name={tv.name} />
                                </div>
                            }

                            {!!tv?.rating?.Awards && tv?.rating?.Awards !== 'N/A' &&
                                <>
                                    <h5 className='ratings'>Awards</h5>
                                    <p>{tv?.rating?.Awards}</p>
                                </>
                            }

                            <h5>Show details</h5>
                            <p>{tv.name} has a total of {tv.number_of_episodes} {pluralize('episode', tv.number_of_episodes)} over {tv.number_of_seasons} {pluralize('season', tv.number_of_seasons)}</p>


                            {!!tv.first_air_date && <div>
                                <h5>First air date </h5>
                                <p>{new Date(tv.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                            </div>
                            }

                            {/* {!!tv.last_air_date && <div>
                                <h5>Latest air date </h5>
                                <p>{new Date(tv.last_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                            </div>
                            }

                            {!!tv.last_episode_to_air &&
                                <div>
                                    <h5>Latest aired episode</h5>
                                    <ul className='dashed'>
                                        <li key={tv.last_episode_to_air?.id}>
                                            {tv.last_episode_to_air?.name}
                                            <ul className='dashed'>
                                                <li>{new Date(tv.last_episode_to_air.air_date).toLocaleDateString('en-US', dateFormatting_YMD)}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            }

                            {!!tv.next_episode_to_air &&
                                <div>
                                    <h5>Next episode to air</h5>
                                    <ul className='dashed'>
                                        <li key={tv.next_episode_to_air?.id}>
                                            {tv.next_episode_to_air?.name}
                                            <ul className='dashed'>
                                                <li>{new Date(tv.next_episode_to_air.air_date).toLocaleDateString('en-US', dateFormatting_YMD)}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            } */}

                            {!!tv.genres?.length &&
                                <div>
                                    <h5>{pluralize('Genre', tv.genres?.length)}</h5>
                                    <div>
                                        {tv.genres?.map((genre, index) => <span key={genre.id}>{(index ? ', ' : '') + genre.name}</span>)}
                                    </div>
                                </div>
                            }

                            {!!tv.spoken_languages &&
                                <div>
                                    <h5>Spoken {pluralize('language', tv.spoken_languages?.length)}</h5>
                                    <div>
                                        {tv.spoken_languages?.map((spoken_languages, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + spoken_languages.english_name}</span>)}
                                    </div>
                                </div>
                            }

                            {!!tv.status &&
                                <div>
                                    <h5>Show status</h5>
                                    <p>{tv.status}</p>
                                </div>
                            }

                            {!!tv.production_companies &&
                                <div>
                                    <h5>Production {pluralize('company', tv.production_companies?.length)}</h5>
                                    <div>
                                        {tv.production_companies?.map((production_companies, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + production_companies.name}</span>)}
                                    </div>
                                </div>
                            }


                            {tv?.rating?.Writer &&
                                <>
                                    <h5 className='ratings'>{pluralize('Writer', tv?.rating?.Writer?.split(',')?.length)}</h5>
                                    <p>{tv?.rating?.Writer}</p>
                                </>
                            }

                            {!!tv.networks &&
                                <div>
                                    <h5>{pluralize('Network', tv.networks?.length)}</h5>
                                    <div>
                                        {tv.networks?.map((networks, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + networks.name}</span>)}
                                    </div>
                                </div>
                            }

                            {!!tv_rating?.rating &&
                                <div>
                                    <h5>Content Rating</h5>
                                    <span className='content-rating'>
                                        {tv_rating?.rating}
                                    </span>
                                </div>
                            }

                            <h5>Share</h5>
                            <Share url={window.location.href} title={tv.name} description={tv.overview} />

                            {new Date(tv?.first_air_date) >= new Date() || !tv.providers?.results?.[local] ? '' :
                                <>
                                    <h5>Where to watch</h5>
                                    {!tv.providers?.results?.[local] ? `We're sorry, but data is not available for ${listOfCountries[local]} at this time.` :
                                        <p>Showing results for {listOfCountries[local]}</p>}
                                </>
                            }

                            <div className='where-to-watch'>
                                {!!tv.providers?.results?.[local]?.flatrate &&
                                    <div>
                                        <h6>Stream</h6>
                                        <ul className='dashed'>
                                            {tv.providers?.results?.[local]?.flatrate?.map(results =>
                                                <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                            )}
                                        </ul>
                                    </div>
                                }

                                {!!tv.providers?.results?.[local]?.buy && <div>
                                    <h6>Buy</h6>
                                    <ul className='dashed'>
                                        {tv.providers?.results?.[local]?.buy?.map(results =>
                                            <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                        )}
                                    </ul>
                                </div>
                                }

                                {!!tv.providers?.results?.[local]?.rent && <div>
                                    <h6>Rent</h6>
                                    <ul className='dashed'>
                                        {tv.providers?.results?.[local]?.rent?.map(results =>
                                            <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                        )}
                                    </ul>
                                </div>
                                }

                                {!!tv.providers?.results?.[local]?.free && <div>
                                    <h6>Free</h6>
                                    <ul className='dashed'>
                                        {tv.providers?.results?.[local]?.free?.map(results =>
                                            <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                        )}
                                    </ul>
                                </div>
                                }

                                {!!tv.providers?.results?.[local]?.ads && <div>
                                    <h6>Free With Ads</h6>
                                    <ul className='dashed'>
                                        {tv.providers?.results?.[local]?.ads?.map(results =>
                                            <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                        )}
                                    </ul>
                                </div>
                                }
                            </div>

                            <Reviews reviews={tv.reviews} />

                            {!!tv.seasons?.length && <>
                                <h5 className='margin-bottom season-picker'>{pluralize('Season', tv.seasons?.length)}
                                    <select id='season-select' value={selectedSeason} onChange={handleChange} className='dropdown-button' title='Select Season'>
                                        {tv.seasons?.map((season) => (
                                            <option key={season.id} value={season.season_number}>
                                                {season.name}
                                            </option>
                                        ))}
                                    </select>
                                </h5>
                                {/* <p>Air date: {new Date(seasons[selectedSeason]?.air_date)?.toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                                <p>Overview: {seasons[selectedSeason]?.overview}</p> */}
                                <div className='scroll-tray'>
                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-left'
                                            onClick={() => slider('scroll-tray-seasons', 'left')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                        </button>
                                    )}

                                    <div className='scroll-tray' id='scroll-tray-seasons'>
                                        {!loadedSeasons ? (
                                            Array.from({ length: 10 }).map((_, index) => (
                                                <Skeleton key={index} size='episodes' />
                                            ))
                                        ) : (
                                            seasons[selectedSeason]?.episodes?.map(episode => (
                                                <div key={episode.id} classnames='fade' timeout={500}>
                                                    <a href={`/view/tv/${tvID}/season/${selectedSeason}/episode/${episode.episode_number}`} ref={ref}>
                                                        <div className='holder episodes'>
                                                            {calculateNew(episode?.air_date) > 7 ? '' : <div className='new'>NEW</div>}
                                                            <div className='gradient'></div>

                                                            {!episode.still_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w1280${episode.still_path}`} alt={episode.name} />}

                                                            <div className='overlay episode-info'>
                                                                <div className='title'>Episode {episode.episode_number}: {episode.name}</div>
                                                                <ul className='subtitle content-short-overview'>
                                                                    {!!episode.vote_average && <li>{episode.vote_average?.toFixed(1)}/10</li>}
                                                                    {!!episode.air_date && <li>{new Date(episode?.air_date)?.toLocaleDateString('en-US', dateFormatting_YMD)}</li>}
                                                                    {!!episode?.runtime && <li>{time_convert(episode.runtime)}</li>}
                                                                </ul>
                                                                {!episode.overview ? '' :
                                                                    <div className='subtitle description'>
                                                                        <p>{episode.overview}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                    </div>

                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-right'
                                            onClick={() => slider('scroll-tray-seasons', 'right')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                        </button>
                                    )}
                                </div>
                            </>}

                            {!tv?.credits?.cast?.length ? '' : <>
                                <h5 className='margin-bottom'>Cast</h5>

                                <div className='scroll-tray'>
                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-left'
                                            onClick={() => slider('scroll-tray-cast', 'left')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                        </button>
                                    )}

                                    <div className='scroll-tray' id='scroll-tray-cast'>
                                        {tv?.credits?.cast?.map(cast => (
                                            <a className='link' href={`/view/person/${cast.id}`} key={cast.id} ref={ref}>
                                                <div className='holder'>
                                                    <div className='gradient'></div>
                                                    {!cast.profile_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${cast.profile_path}`} alt={cast.name} />}
                                                    <div className='overlay'>
                                                        <div className='subtitle'>
                                                            {cast.character && `${cast.character}`}
                                                        </div>
                                                        <div className='title'>{cast.name}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>

                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-right'
                                            onClick={() => slider('scroll-tray-cast', 'right')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                        </button>
                                    )}
                                </div>

                            </>}

                            {!tv?.recommendations?.results?.length ? '' : <>
                                <h5 className='margin-bottom'>You May Also Like</h5>

                                <div className='scroll-tray'>
                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-left'
                                            onClick={() => slider('scroll-tray-recommended', 'left')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                        </button>
                                    )}

                                    <div className='scroll-tray' id='scroll-tray-recommended'>
                                        {tv?.recommendations?.results?.filter((image) => image.poster_path != null).map(results => (
                                            <a className='link' href={`/view/tv/${results.id}`} key={results.id} ref={ref}>
                                                <div className='holder'>
                                                    <div className='gradient'></div>

                                                    {!results.poster_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${results.poster_path}`} alt={results.name} />}

                                                    <div className='overlay'>
                                                        <div className='subtitle'>
                                                            {!!results.vote_average && `${results?.vote_average?.toFixed(1)}/10 — `}
                                                            {!!results?.first_air_date && `${new Date(results?.first_air_date) < new Date() ? new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                        </div>
                                                        <div className='title'>{results.name}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>

                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-right'
                                            onClick={() => slider('scroll-tray-recommended', 'right')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                        </button>
                                    )}
                                </div>

                            </>}

                            <FsLightbox className='lightbox' toggler={galleryToggler} type='image' sources={gallery} showThumbsOnMount={true} thumbs={gallery} />
                            <FsLightbox toggler={videoToggler} sources={trailers} />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
