// React
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'

//Custom Functions
import { CustomNavbar, Share } from '../../index.js'
import { ageCalculator, slider, deathCalculator, dateFormatting_Y, dateFormatting_YMD } from '../../../Helpers/utils.js'

// Firebase
import { auth } from '../../../firebase'

// Custom Imports
import Loading from '../../../Assets/Images/loading.svg'

//Ligthbox
import FsLightbox from 'fslightbox-react'
import pluralize from 'pluralize'

import { NotFound } from '../../../index.js'
import { onAuthStateChanged } from 'firebase/auth'
import useFavoritesStore from '../../../Store/useFavoritesStore.jsx'

export default function PeopleDetails() {
    const { personID } = useParams()

    // State management for content
    const [person, setPerson] = useState([])
    const [isLoaded, setLoaded] = useState(false)

    // Trailers & Gallery
    const gallery = person?.images?.profiles?.map(results => `https://image.tmdb.org/t/p/original${results.file_path}`)
    const [galleryToggler, setGalleryToggler] = useState(false)

    // Other state management
    const safeSearch = localStorage.getItem('safe-search')

    // API URL
    const url = `https://api.skuflic.net/person/${personID}?include_adult=${safeSearch}&append_to_response=videos,similar,credits,recommendations,images,release_dates,content_ratings,combined_credits,external_ids,tagged_images&include_image_language=en,null`

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    const getPerson = async () => {
        try {
            const response = await fetch(url, { cache: 'force-cache' })
            const data = await response.json()
            setPerson(data)
            setLoaded(true)
        } catch (error) {
            console.error(error)
        }
    }

    // Favorites
    const { addToFavorites, isFavorite, removeFromFavorites } = useFavoritesStore();

    const fetchInitialFavorites = useFavoritesStore((state) => state.fetchInitialFavorites);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchInitialFavorites();
            }
        });

        return () => unsubscribe();
    }, [fetchInitialFavorites]);

    useEffect(() => {
        getPerson()
    }, [personID])

    document.title = `${!person.name ? `Reel` : `${person.name} - Reel`}`

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    if (!isLoaded) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else if (!personID) {
        return <NotFound />
    } else {
        return (
            <>
                <Helmet>
                    <meta property='og:description' content={person.biography} />
                    <meta property='og:image' content={`https://image.tmdb.org/t/p/h632${person?.profile_path}`} />
                    <meta property='og:site_name' content={`Reel`} />
                    <meta property='og:title' content={!person.name ? `Reel` : `${person.name} - Reel`} />
                    <meta property='og:type' content='website' />

                    <meta name='twitter:title' content={!person.name ? `Reel` : `${person.name} - Reel`} />
                    <meta name='twitter:description' content={person.biography} />
                    <meta name='twitter:image' content={`https://image.tmdb.org/t/p/h632${person?.profile_path}`} />
                    <meta name='twitter:card' content={person.biography} />
                </Helmet>

                <CustomNavbar />
                <div className='holder-container'>
                    <div className='custom-container'>

                        {!person.profile_path ? <span className='material-symbols-outlined content-image not-available'> hide_image </span> : <img className='content-image person' src={`https://image.tmdb.org/t/p/w780${person.profile_path}`} alt={person.name} key={person.id} />}

                        <h3>{person.name}</h3>

                        {person.adult && <p className='main-button details'>This page may include potentially sensitive or age-restricted content which may be inappropriate for minors. Viewer discretion is advised.</p>}

                        {!person.biography ? '' :
                            <div className='content-overview'>
                                <p className={expandedItem === person.id ? 'read-less' : 'read-more' && person.biography.length < 300 ? null : 'read-more'}>{person.biography}</p>
                                {person.biography.length > 300 && (
                                    <button onClick={() => handleToggle(person.id)} className='read-more-button'>
                                        {expandedItem === person.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        }

                        <div className='buttons'>
                            <div>
                                <span
                                    className='material-symbols-outlined'
                                    onClick={isFavorite(person) ? () => removeFromFavorites(person) : () => addToFavorites(person, 'person')}
                                >
                                    {isFavorite(person) ? 'heart_minus' : 'favorite'}
                                </span>
                                <p>{isFavorite(person) ? 'Unfavorite' : 'Favorite'}</p>
                            </div>

                            {gallery &&
                                <div>
                                    <span className='material-symbols-outlined' onClick={() => setGalleryToggler(!galleryToggler)}>photo_library</span>
                                    <p>Gallery</p>
                                </div>
                            }

                            {person.imdb_id &&
                                <div>
                                    <a href={`https://www.imdb.com/name/${person.external_ids?.imdb_id}`} target='_blank' rel='noreferrer'>
                                        <span className='material-symbols-outlined'>open_in_new</span>
                                    </a>
                                    <p>Website</p>
                                </div>
                            }
                        </div>

                        <div className='details'>
                            {!!person.birthday && <div>
                                <h5>Date of birth</h5>
                                <p>{new Date(person.birthday).toLocaleDateString('en-US', dateFormatting_YMD)} {!person.deathday ? `(${ageCalculator(person.birthday)} ${pluralize('year', ageCalculator(person.birthday))} old)` : ''}</p>
                            </div>
                            }

                            {!!person.deathday && <div>
                                <h5>Date of death</h5>
                                <p>{new Date(person.deathday).toLocaleDateString('en-US', dateFormatting_YMD)} ({deathCalculator(person.birthday, person.deathday)} {pluralize('years', deathCalculator(person.birthday, person.deathday))} old)</p>
                            </div>
                            }

                            {!!person.known_for_department && <div>
                                <h5>Known for</h5>
                                <p>{person.known_for_department}</p>
                            </div>
                            }

                            {!!person.gender && <div>
                                <h5>Gender</h5>
                                <p>{person.gender === 1 ? 'Female' : 'Male'}</p>
                            </div>
                            }

                            {!!person.place_of_birth && <div>
                                <h5>Place of birth</h5>
                                <p>{person.place_of_birth}</p>
                            </div>
                            }

                            {(!person.external_ids.facebook_id) && (!person.external_ids.twitter_id) && (!person.external_ids.instagram_id) ? '' : <>
                                <h5>Social media</h5>
                                <div>
                                    <ul className='dashed'>
                                        {!!person.external_ids?.facebook_id && <li><a href={`https://www.facebook.com/${person.external_ids?.facebook_id}`} target='_blank' rel='noreferrer' className='main-button'>Facebook</a></li>}
                                        {!!person.external_ids?.twitter_id && <li><a href={`https://www.twitter.com/${person.external_ids?.twitter_id}`} target='_blank' rel='noreferrer' className='main-button'>Twitter</a></li>}
                                        {!!person.external_ids?.instagram_id && <li><a href={`https://www.instagram.com/${person.external_ids?.instagram_id}`} target='_blank' rel='noreferrer' className='main-button'>Instagram</a></li>}
                                    </ul>
                                </div>
                            </>}

                            <h5>Share</h5>
                            <Share url={window.location.href} title={person.name} />

                            {!!person?.combined_credits?.cast?.length && <>
                                <h5 className='margin-bottom'>Starred in</h5>

                                <div className='scroll-tray'>
                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-left'
                                            onClick={() => slider('scroll-tray-starred-in', 'left')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                        </button>
                                    )}

                                    <div className='scroll-tray ' id='scroll-tray-starred-in'>
                                        {person?.combined_credits?.cast?.map(cast => (
                                            <div key={cast.id} ref={ref}>
                                                <a className='link' href={`/view/${cast.media_type}/${cast.id}`}>
                                                    <div className='holder'>
                                                        {cast.adult && <span className='material-symbols-outlined adult-content-warning'> explicit </span>}
                                                        <div className='gradient'></div>
                                                        {!cast.poster_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${cast.poster_path}`} alt={cast.original_title} />}
                                                        <div className='overlay'>
                                                            <div className='subtitle'>
                                                                {cast.vote_average && `${cast?.vote_average?.toFixed(1)}/10 — `}
                                                                {cast?.release_date && `${new Date(cast?.release_date) < new Date() ? new Date(cast?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(cast?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                                {cast?.first_air_date && `${new Date(cast?.first_air_date) < new Date() ? new Date(cast?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(cast?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                            </div>
                                                            <div className='title'>{cast.media_type === 'movie' ? cast.title : cast.name}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </div>

                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-right'
                                            onClick={() => slider('scroll-tray-starred-in', 'right')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                        </button>
                                    )}
                                </div>

                                <FsLightbox className='lightbox' toggler={galleryToggler} type='image' sources={gallery} showThumbsOnMount={true} thumbs={gallery} />

                            </>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}