import useImageOnLoad from '../Hooks/useImageOnLoad'

const Image = ({ lowResSrc, highResSrc, title, identifier, id }, props) => {
    const { handleImageOnLoad, transitionStyles } = useImageOnLoad()

    const lowResStyle = { ...transitionStyles.lowRes }
    const hightResStyle = { ...transitionStyles.highRes }

    return (
        <>
            <img src={lowResSrc} key={identifier} className='custom-container-background main-background' style={lowResStyle} title={title} alt={`Reel`} />
            <img src={highResSrc} key={identifier} className='custom-container-background main-background' style={hightResStyle} title={title} onLoad={handleImageOnLoad} alt={`Reel`} />
        </>
    )
}

export default Image