import { useState, useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Custom Imports
import { dateFormatting_Y, dateFormatting_YMD, errorNotification, listOfGenres } from '../../Helpers/utils';

export default function Search() {
    const [results, setResults] = useState([]);
    const [query, setQuery] = useState('');
    const [speakStatus, setSpeakStatus] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);
    const safeSearch = localStorage.getItem('safe-search');
    const inputSearchRef = useRef(null);
    const cache = useRef(new Map());

    const {
        finalTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
        listening,
    } = useSpeechRecognition();

    const fetchResults = useCallback(
        debounce(async (query) => {
            if (!query) return;
            const cachedResponse = cache.current.get(query);
            if (cachedResponse) {
                setResults(cachedResponse);
            } else {
                try {
                    const response = await fetch(
                        `https://api.skuflic.net/search/multi?language=en-US&query=${query}&include_adult=${safeSearch}&append_to_response=videos,similar,credits,recommendations,images,release_dates,releases`
                    );
                    const data = await response.json();
                    cache.current.set(query, data);
                    setResults(data);
                } catch (error) {
                    errorNotification('Error fetching results');
                }
            }
        }, 300),
        [safeSearch]
    );

    const handleInputChange = (e) => {
        const query = e.target.value.trim();
        setQuery(query);
        fetchResults(query);
    };

    const toogleSearch = () => {
        setSearchStatus((current) => !current);
        if (speakStatus === true) {
            setSpeakStatus((current) => !current);
            SpeechRecognition.stopListening();
        }
        setQuery('');
    };

    const toggleSpeak = () => {
        setQuery('');
        inputSearchRef.current.value = null;
        if (!browserSupportsSpeechRecognition) {
            errorNotification('This browser does not support speech recognition.');
        } else if (!isMicrophoneAvailable) {
            errorNotification('You must allow microphone access to use speech recognition.');
        } else {
            setSpeakStatus((current) => !current);
            !speakStatus === true ? SpeechRecognition.startListening({ language: 'en-US' }) : SpeechRecognition.stopListening();
        }
    };

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            errorNotification('This browser does not support speech recognition.');
        } else if (!isMicrophoneAvailable) {
            errorNotification('You must allow microphone access to use speech recognition.');
        }
    }, [browserSupportsSpeechRecognition, isMicrophoneAvailable, speakStatus, listening]);

    useEffect(() => {
        if (!listening) {
            setSpeakStatus(false);
        }
    }, [listening]);

    useEffect(() => {
        if (finalTranscript !== '') {
            setSpeakStatus(false);
            setQuery(finalTranscript);
            fetchResults(finalTranscript);
            inputSearchRef.current.value = finalTranscript;
        }
    }, [finalTranscript, safeSearch, fetchResults]);

    return (
        <div>
            <span className={searchStatus === true ? 'input active-mobile' : 'input'}>
                {searchStatus && (
                    <>
                        <input
                            type='search'
                            id='input-search'
                            placeholder='Find movies, TV shows, actors, celebrities & more'
                            aria-label='Search'
                            className='nav-search'
                            onChange={handleInputChange}
                            ref={inputSearchRef}
                        />
                        <span
                            className={!speakStatus ? 'material-symbols-outlined' : 'material-symbols-outlined active'}
                            id='mic'
                            onClick={toggleSpeak}
                        >
                            {!speakStatus ? 'mic' : 'mic_off'}
                        </span>
                    </>
                )}
                <span className='material-symbols-outlined' onClick={toogleSearch}>
                    {!searchStatus ? 'search' : 'close'}
                </span>
            </span>

            {speakStatus && listening && (
                <div className='speech'>
                    <h1>Speak</h1>
                    <p>
                        Speech recognition supports only the English language.
                        <br />
                        If you're having issues with speech recognition, try using the regular search.
                    </p>
                    <span className='material-symbols-outlined icon'>graphic_eq</span>
                    <div className='background'></div>
                </div>
            )}

            {query && (
                <div className='results'>
                    <p>
                        Results for: {query}
                        <br />
                        Remember to check your <a href='/safesearch'>SafeSearch</a> settings.
                    </p>
                    {results?.results
                        ?.filter((image) => image.poster_path || image.profile_path != null)
                        .map((results) => (
                            <div className='holder' key={results.id}>
                                {results.adult && (
                                    <span className='material-symbols-outlined adult-content-warning'>explicit</span>
                                )}

                                {results.poster_path === null || results.profile_path === null ? (
                                    <a href={`/view/${results.media_type}/${results.id}`}>
                                        <span className='material-symbols-outlined image not-available'>
                                            hide_image
                                        </span>
                                    </a>
                                ) : (
                                    <a href={`/view/${results.media_type}/${results.id}`}>
                                        <img
                                            src={`https://image.tmdb.org/t/p/w300${results.profile_path ?? results.poster_path}`}
                                            alt={results.title ?? results.name}
                                            className={results.media_type == 'person' && 'person'}
                                        />
                                    </a>
                                )}

                                <div className='details' key={results.id}>
                                    <h1>
                                        <a
                                            className='link'
                                            href={`/view/${results.media_type}/${results.id}`}
                                            key={results.id}
                                        >
                                            {results.title ?? results.name}
                                        </a>
                                    </h1>

                                    <p className='overview'>{results.overview}</p>
                                    <ul className='content-short-overview'>
                                        {!!results.vote_average && <li>{results.vote_average?.toFixed(1)}/10</li>}

                                        {!!results?.genre_ids && results?.genre_ids?.length > 0 && <li>
                                            {results.genre_ids?.map((genre, index) => (
                                                <span key={genre.id}>
                                                    {(index ? ', ' : '') + listOfGenres[genre]}
                                                </span>
                                            ))}
                                        </li>}

                                        {results?.release_date &&
                                            <li>
                                                {new Date(results.release_date) < new Date() ?
                                                    new Date(results.release_date).toLocaleDateString('en-US', dateFormatting_Y) :
                                                    `Releases ${new Date(results.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}
                                            </li>
                                        }
                                        {results?.first_air_date &&
                                            <li>{new Date(results?.first_air_date) < new Date()
                                                ? new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y)
                                                : `Releases ${new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}</li>}
                                    </ul>
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
}
