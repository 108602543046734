// React
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Turnstile } from '@marsidev/react-turnstile'

// Firebase
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, OAuthProvider, signOut, GithubAuthProvider } from 'firebase/auth'
import { auth } from '../../firebase'

// Custom Functions
import { CustomNavbar } from '../../Components'
import { authErrorCodes, errorNotification } from '../../Helpers/utils'
import MailChecker from 'mailchecker'

// Assets
import Google from '../../Assets/Images/google.svg'
import Twitter from '../../Assets/Images/twitter.svg'
import Microsoft from '../../Assets/Images/microsoft.svg'
import GitHub from '../../Assets/Images/github.svg'

export default function Register() {
    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [token, setToken] = useState()
    const reference = useRef()
    const [registered, setRegistered] = useState(false)

    const googleProvider = new GoogleAuthProvider()
    const twitterProvider = new TwitterAuthProvider()
    const microsoftProvider = new OAuthProvider('microsoft.com')
    const githubProvider = new GithubAuthProvider();

    const socialSignUp = async (props) => {
        try {
            await signInWithPopup(auth, props)
            navigate('/account')
        } catch (error) {
            errorNotification(authErrorCodes[error.code])
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const response = await fetch(`https://turnstile.skuflic.net?rt=${token}`)
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    reference.current?.reset();
                }
                return data.success;
            });

        if (MailChecker.isValid(email) && response === true) {
            try {
                const { user } = await createUserWithEmailAndPassword(auth, email, password)
                await sendEmailVerification(user)
                updateProfile(user, { displayName: `${firstName} ${lastName}` })
                setRegistered(true)
                signOut(auth)
            } catch (error) {
                errorNotification(authErrorCodes[error.code])
                reference.current?.reset();
            }
        } else {
            errorNotification('Your email address is invalid.')
        }
    }


    useEffect(() => {
        if (auth.currentUser) {
            navigate('/account');
        }
    }, [])


    document.title = `Create Your Account - Reel`

    if (!registered) {
        return (
            <>
                <div className='main'>
                    <CustomNavbar />
                    <div className='account'>
                        <h1>Create Your Account</h1>
                        <p>Already have an account? <a href='/account/login'>Sign In</a></p>
                        <form onSubmit={onSubmit}>
                            <div className='input-field'>
                                <div className='help-text'>FIRST NAME</div>
                                <input id='first-name' name='firstname' type='text' required className='field' onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                            </div>

                            <div className='input-field'>
                                <div className='help-text'>LAST NAME</div>
                                <input id='last-name' name='lastname' type='text' required className='field' onChange={(e) => setLastName(e.target.value)} value={lastName} />
                            </div>

                            <div className='input-field'>
                                <div className='help-text'>EMAIL ADDRESS</div>
                                <input id='email-address' name='email' type='email' required className='field' onChange={(e) => setEmail(e.target.value)} value={email} />
                            </div>

                            <div className='input-field'>
                                <div className='help-text'>PASSWORD</div>
                                <input id='password' name='password' type='password' required className='field' onChange={(e) => setPassword(e.target.value)} value={password} />
                            </div>

                            <button type='submit' className='button'>Create Account</button>
                            <div className='separator'>OR</div>
                            <button onClick={() => socialSignUp(googleProvider)} className='button google'><img src={Google} alt='Continue With Google' />Continue With Google</button>
                            <button onClick={() => socialSignUp(twitterProvider)} className='button twitter'><img src={Twitter} alt='Continue With X' />Continue With X</button>
                            <button onClick={() => socialSignUp(microsoftProvider)} className='button microsoft'><img src={Microsoft} alt='Sign In With Microsoft' />Continue With Microsoft</button>
                            <button onClick={() => socialSignUp(githubProvider)} className='button github'><img src={GitHub} alt='Continue With GitHub' />Continue With GitHub</button>
                        </form>
                        <span className='disclaimer'>By clicking 'Create Account', you confirm that you have read and agree<br /> to the <a href='/servicesagreement'>Services Agreement</a>, and <a href='/privacy'>Privacy Policy</a>.</span>
                        {<Turnstile
                            siteKey='0x4AAAAAAAM_D35F4o3pwJXW'
                            className='turnstile'
                            options={{
                                action: 'register',
                                theme: 'dark',
                                size: 'normal',
                                language: 'en',
                                refreshExpired: 'auto',
                                appearance: 'interaction-only'
                            }}
                            onSuccess={setToken} ref={reference} />}
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='main'>
                    <CustomNavbar />
                    <div className='account'>
                        <h1>Almost There 🤩</h1>
                        <p>{firstName}, click the link sent to your email address ({email}) to activate your <b>Reel</b> account.</p>
                    </div>
                </div>
            </>
        )
    }
}