// React
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useLocation } from 'react-router-dom'

// Custom Functions
import { CustomNavbar, Skeleton } from '../../Components/index'
import { slider, dateFormatting_YMD, errorNotification } from '../../Helpers/utils'

// Firebase
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db, auth } from '../../firebase'
import { signOut, onAuthStateChanged } from 'firebase/auth'
import useFavoritesStore from '../../Store/useFavoritesStore'

// Custom Assets
import Loading from '../../Assets/Images/loading.svg'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function Favorites() {
    const navigate = useNavigate()
    const query2 = useQuery().get('sort_by')
    const { removeFromFavorites } = useFavoritesStore();

    const [favorites, setFavorites] = useState([])
    const [loading, setLoading] = useState(true)
    const [rerender, setRerender] = useState(false)
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const userCollectionRef = collection(db, auth.currentUser.uid);
                    const sortBy = query(userCollectionRef, where('type', '==', query2 || 'default'));

                    const querySnapshot = await getDocs(query2 ? sortBy : userCollectionRef);

                    const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    setFavorites(data);
                } catch (error) {
                    console.error('Error fetching favorites:', error);
                    // Handle error display or logging here, instead of directly manipulating DOM
                } finally {
                    setLoaded(true)
                    setLoading(false);
                }
            } else {
                navigate('/account/login');
            }
        });

        return () => unsubscribe(); // Cleanup function to unsubscribe from onAuthStateChanged

    }, [navigate, query2, rerender]);

    const deleteFromFavorite = async (props) => {
        try {
            setLoading(true)
            removeFromFavorites(props)
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }
        setRerender(!rerender);
    };


    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    document.title = `Your Favorite Content - Reel`

    function sortDataBy(data) {
        let sortedData;
        sortedData = data.sort(function (a, b) {
            let x = a.name?.toLowerCase();
            let y = b.name?.toLowerCase();
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
        });
        return sortedData;
    }

    return (
        !isLoaded ? <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></> : (
            <div className='main'>
                <CustomNavbar />
                <div className='custom-container content'>
                    <h3 className='fw-200'>Your Favorite Content {favorites[0] == null ? 'Is Empty' : ''}</h3>
                    <p>Your Favorite Content is the place to keep track of the titles you love. You can sort Your Favorite Content by movies, TV shows, and people.
                        <br />
                        If you are not <b>{auth.currentUser?.displayName}</b>, click <span className='pointer' onClick={() => signOut(auth).then(() => { navigate('/account/login') })}>here.</span>
                    </p>
                    <div className='scroll-tray' style={{ paddingBottom: '20px' }}>
                        <a href='/account/favorites' className={query2 == null ? 'sort active' : 'sort'}>Everything</a>
                        <a href='?sort_by=tv' className={query2?.includes('tv') ? 'sort active' : 'sort'}>TV Shows</a>
                        <a href='?sort_by=movie' className={query2?.includes('movie') ? 'sort active' : 'sort'}>Movies</a>
                        <a href='?sort_by=person' className={query2?.includes('person') ? 'sort active' : 'sort'}>People</a>
                    </div>

                    <div className='scroll-tray'>
                        {!inView && (
                            <button
                                id='slide'
                                type='button'
                                className='arrow-buttons arrow-left'
                                onClick={() => slider('scroll-tray-content', 'left')}
                            >
                                <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                            </button>
                        )}

                        <div className='scroll-tray' id='scroll-tray-content' ref={ref}>
                            {loading ? (
                                Array.from({ length: 10 }).map((_, index) => (
                                    <Skeleton key={index} />
                                ))
                            ) : (
                                sortDataBy(favorites)?.map(results =>
                                    <div className='holder' key={results.id}>
                                        <div className='ribbon' onClick={() => deleteFromFavorite(results)}>
                                            <span className='material-symbols-outlined'> heart_minus </span>
                                        </div>
                                        <a href={`/view/${results.type}/${results.reference}`} key={favorites.reference}>
                                            <div className='gradient favorites'></div>
                                            {!results.image ? <span className='material-symbols-outlined image not-available' style={{ height: '300px' }}> hide_image </span> : <img className='image favorites' src={`https://image.tmdb.org/t/p/w780${results.image}`} alt={results.name} />}
                                            <div className='overlay'>
                                                <div className='subtitle'>
                                                    {`Added on ${new Date(results?.timestamp.seconds * 1000).toLocaleDateString('en-US', dateFormatting_YMD)}`}
                                                </div>
                                                <div className='title'>{results.name}</div>
                                            </div>
                                        </a>
                                    </div>

                                )
                            )}
                        </div>

                        {!inView && (
                            <button
                                id='slide'
                                type='button'
                                className='arrow-buttons arrow-right'
                                onClick={() => slider('scroll-tray-content', 'right')}
                            >
                                <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                            </button>
                        )}

                    </div>
                </div>
            </div>
        )

    )

}