import { useRef } from 'react'

export default function AccordionItem({ faq, active, onToggle }) {
    const { question, answer, cta, url } = faq;
    const contentEl = useRef();

    return (
        <li className={`accordion_item ${active ? 'active' : ''}`}>
            <button className='button' onClick={onToggle}>
                <span className='title'>{question}</span>
                {active ? <span className='material-symbols-outlined'> close </span> : <span className='material-symbols-outlined'> add </span>}
            </button>
            <div ref={contentEl} className='answer_wrapper' style={active ? { height: contentEl.current.scrollHeight } : { height: '0px' }} >
                <div className='answer'>{answer}  <a href={url}>{cta}</a></div>
            </div>
        </li>
    );
}