// React
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'

// Custom Functions
import { CustomNavbar, Rate, Reviews, Share } from '../../index.js'
import { time_convert, slider, listOfCountries, dateFormatting_Y, dateFormatting_YMD, number_formatter } from '../../../Helpers/utils.js'

// Firebase & Zustand
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../../firebase.js'
import useFavoritesStore from '../../../Store/useFavoritesStore.jsx'

// Custom Imports
import Loading from '../../../Assets/Images/loading.svg'
import Image from '../../Image/Image'
import { NotFound } from '../../../index.js'

// Ligthbox
import FsLightbox from 'fslightbox-react'
import pluralize from 'pluralize'

export default function MovieDetails() {
    let params = useParams()

    // State management for content
    const [movie, setMovieData] = useState({})

    // Trailers & Gallery
    const gallery = movie?.images?.backdrops?.map(results => `https://image.tmdb.org/t/p/original${results.file_path}`)
    const trailers = movie?.videos?.results?.map(results => `https://www.youtube.com/watch?v=${results.key}`)

    const [galleryToggler, setGalleryToggler] = useState(false)
    const [videoToggler, setVideoToggler] = useState(false)

    // Other state management
    const [isLoaded, setLoaded] = useState(false)

    // Favorites
    const { addToFavorites, isFavorite, removeFromFavorites } = useFavoritesStore();

    const fetchInitialFavorites = useFavoritesStore((state) => state.fetchInitialFavorites);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchInitialFavorites();
            }
        });

        return () => unsubscribe();
    }, [fetchInitialFavorites]);

    // SafeSearch Settings and Country Management
    let country = localStorage.getItem('detected-country')
    let local = localStorage.getItem('selected-country') ?? country

    const CACHE_DURATION = 3600000; // 1 hour in milliseconds
    const cache = new Map();

    useEffect(() => {
        const fetchMovieData = async (id, mediaType) => {

            const cacheKey = `${mediaType}-${id}`;
            const cachedData = cache.get(cacheKey);
            const now = Date.now();

            if (cachedData && now - cachedData.timestamp < CACHE_DURATION) {
                return cachedData.data;
            }

            try {
                const safeSearch = localStorage.getItem('WatchTV_SafeSearch') || 'false';
                const movieUrl = `https://api.skuflic.net/movie/${params.movieID}?include_adult=${safeSearch}&append_to_response=videos,reviews,similar,credits,recommendations,images,release_dates,releases&include_image_language=en,null`;

                // Fetch movie details
                const movieResponse = await fetch(movieUrl);
                if (!movieResponse.ok) {
                    throw new Error('Failed to fetch movie details');
                }
                const movieDetails = await movieResponse.json();

                // Fetch ratings and providers in parallel
                const [ratingResponse, providersResponse] = await Promise.all([
                    fetch(`https://ratings.skuflic.net?id=${movieDetails.imdb_id}`),
                    fetch(`https://api.skuflic.net/movie/${params.movieID}/watch/providers`, { cache: 'force-cache' }),
                ]);

                // Check responses
                if (!ratingResponse.ok) {
                    throw new Error('Failed to fetch ratings');
                }
                if (!providersResponse.ok) {
                    throw new Error('Failed to fetch providers');
                }

                const dataRating = await ratingResponse.json();
                const dataProviders = await providersResponse.json();

                // Update state with movie data
                setMovieData({
                    ...movieDetails,
                    rating: dataRating,
                    providers: dataProviders,
                });

                // Set loaded state to true
                setLoaded(true);
            } catch (error) {
                console.error('Error fetching movie data:', error);
                // Optionally handle error state or display error message
            }
        };

        fetchMovieData();
    }, [params.movieID]);

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    document.title = `${!movie.title ? `Reel` : `${movie.title} (${new Date(movie?.release_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`}`

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    let poster = movie?.images?.backdrops.reduce((max, item) => item.vote_average > max.vote_average ? item : max, movie?.images?.backdrops[0])?.file_path;
    let logo = movie?.images?.logos.reduce((max, item) => item.vote_average > max.vote_average ? item : max, movie?.images?.logos[0])?.file_path;

    const movie_rating = movie?.releases?.countries?.find(o => o?.iso_3166_1 === local)

    if (!isLoaded) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else if (!params.movieID) {
        return <NotFound />
    } else {
        return (
            <>
                <Helmet>
                    <meta property='og:description' content={movie.tagline} />
                    <meta property='og:image' content={`https://image.tmdb.org/t/p/w1280${movie?.backdrop_path}`} />
                    <meta property='og:site_name' content={`Reel`} />
                    <meta property='og:title' content={!movie.title ? `Reel` : `${movie.title} (${new Date(movie?.release_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`} />
                    <meta property='og:type' content='website' />

                    <meta name='twitter:title' content={!movie.title ? `Reel` : `${movie.title} (${new Date(movie?.release_date)?.toLocaleDateString('en-US', dateFormatting_Y)}) - Reel`} />
                    <meta name='twitter:description' content={movie.tagline} />
                    <meta name='twitter:image' content={`https://image.tmdb.org/t/p/w1280${movie?.backdrop_path}`} />
                    <meta name='twitter:card' content={movie.tagline} />
                </Helmet>

                <CustomNavbar />
                <div className='holder-container'>
                    {poster && <div className='splash'>
                        <Image title={movie.name} id='small' lowResSrc={`https://image.tmdb.org/t/p/w780${poster}`} highResSrc={`https://image.tmdb.org/t/p/original${poster}`} />
                    </div>}

                    <div className='custom-container'>
                        <div className='network'>
                            {!logo ? <h3>{movie.title} ({new Date(movie?.release_date)?.toLocaleDateString('en-US', dateFormatting_Y)})</h3> : <img src={`https://image.tmdb.org/t/p/w780${logo}`} alt={movie.title} />}
                        </div>

                        {movie.adult && <p className='main-button details'>This page may include potentially sensitive or age-restricted content which may be inappropriate for minors. Viewer discretion is advised.</p>}

                        <div className='buttons'>
                            <div>
                                <span
                                    className='material-symbols-outlined'
                                    onClick={isFavorite(movie) ? () => removeFromFavorites(movie) : () => addToFavorites(movie, 'movie')}
                                >
                                    {isFavorite(movie) ? 'heart_minus' : 'favorite'}
                                </span>
                                <p>{isFavorite(movie) ? 'Unfavorite' : 'Favorite'}</p>
                            </div>

                            {gallery &&
                                <div>
                                    <span className='material-symbols-outlined' onClick={() => setGalleryToggler(!galleryToggler)}>photo_library</span>
                                    <p>Gallery</p>
                                </div>
                            }

                            {trailers &&
                                <div>
                                    <span className='material-symbols-outlined' onClick={() => setVideoToggler(!videoToggler)}>animated_images</span>
                                    <p>Trailers</p>
                                </div>
                            }


                            {movie.homepage &&
                                <div>
                                    <a href={movie.homepage} target='_blank' rel='noreferrer'>
                                        <span className='material-symbols-outlined'>open_in_new</span>
                                    </a>
                                    <p>Website</p>
                                </div>
                            }
                        </div>

                        <ul className='content-short-overview'>
                            {!!movie.vote_average && <li>{movie.vote_average?.toFixed(1)}/10</li>}
                            {!!movie.genres && movie.genres.length > 0 && <li>{movie.genres?.map((genre, index) => <>{(index ? ', ' : '') + genre.name}</>)}</li>}
                            {!!movie?.runtime && <li>{time_convert(movie.runtime)}</li>}
                        </ul>

                        <p className='details content-tagline'>{movie.tagline}</p>

                        {!!movie.overview &&
                            <div className='content-overview'>
                                <p className={expandedItem === movie.id ? 'read-less' : 'read-more' && movie.overview.length < 300 ? null : 'read-more'}>{movie.overview}</p>
                                {movie.overview.length > 300 && (
                                    <button onClick={() => handleToggle(movie.id)} className='read-more-button'>
                                        {expandedItem === movie.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        }

                        <div className='details'>
                            {!!movie.vote_average && <>
                                <h5 className='ratings'>Audience Rating</h5>
                                <p>What do others think of {movie.title}?</p>
                            </>
                            }
                            <div className='ratings'>
                                {!!movie.vote_average &&
                                    <div>
                                        <h6 className='TheMovieDatabase'>The Movie Database</h6>
                                        <p>{movie.vote_average?.toFixed(1)}/10 ({number_formatter(movie.vote_count, 1)})</p>
                                    </div>
                                }
                                {movie.rating?.Ratings?.map(result =>
                                    <div key={crypto.randomUUID()}>
                                        <h6 className={`${result.Source?.replace(/\s/g, '')}`}>{result.Source}</h6>
                                        <p id={`${result.Source?.replace(/\s/g, '')}`}>{result.Value}</p>
                                    </div>
                                )}
                            </div>

                            {new Date(movie?.release_date) >= new Date() ? null :
                                <div className='rate'>
                                    <h5>Your Rating</h5>
                                    <p>What do you think of {movie.title}?</p>
                                    <Rate type='movie' id={movie.id} name={movie.title} />
                                </div>
                            }

                            {!!movie?.rating?.Awards && movie?.rating?.Awards !== 'N/A' &&
                                <>
                                    <h5 className='ratings'>Awards</h5>
                                    <p>{movie?.rating?.Awards}</p>
                                </>
                            }

                            {!!movie.release_date && <div>
                                <h5>Release date</h5>
                                <p>{new Date(movie.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                            </div>
                            }

                            {!!movie.belongs_to_collection && <div>
                                <h5>Collection</h5>
                                <a className='collection' href={`/view/collection/${movie.belongs_to_collection.id}`}>{movie?.belongs_to_collection?.name}</a>
                            </div>
                            }

                            {!!movie.genres?.length && <div>
                                <h5>{pluralize('Genre', movie.genres?.length)}</h5>
                                <div>
                                    {movie.genres?.map((genre, index) => <span key={genre.id}>{(index ? ', ' : '') + genre.name}</span>)}
                                </div>
                            </div>
                            }

                            {!!movie.revenue && <div>
                                <h5>Revenue</h5>
                                <p>${movie.revenue?.toLocaleString('de-DE')}</p>
                            </div>
                            }

                            {!!movie.budget && <div>
                                <h5>Budget</h5>
                                <p>${movie.budget?.toLocaleString('de-DE')}</p>
                            </div>
                            }

                            {!!movie.spoken_languages?.length && <div>
                                <h5>Spoken {pluralize('language', movie.spoken_languages?.length)}</h5>
                                <div>
                                    {movie.spoken_languages?.map((spoken_languages, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + spoken_languages.english_name}</span>)}
                                </div>
                            </div>
                            }

                            {!!movie.production_companies?.length && <div>
                                <h5>Production {pluralize('company', movie.production_companies?.length)}</h5>
                                <div>
                                    {movie.production_companies?.map((production_companies, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + production_companies.name}</span>)}
                                </div>
                            </div>
                            }

                            {movie?.rating?.Writer &&
                                <>
                                    <h5 className='ratings'>{pluralize('Writer', movie?.rating?.Writer?.split(',')?.length)}</h5>
                                    <p>{movie?.rating?.Writer}</p>
                                </>
                            }

                            {!!movie_rating?.certification &&
                                <div>
                                    <h5>Content Rating</h5>
                                    <span className='content-rating'>
                                        {movie_rating?.certification}
                                    </span>
                                </div>
                            }

                            <h5>Share</h5>
                            <Share url={window.location.href} title={movie.title} />

                            <div>
                                {new Date(movie?.release_date) >= new Date() || !movie.providers?.results?.[local] ? '' :
                                    <>
                                        <h5>Where to Watch</h5>
                                        {!movie.providers?.results?.[local] ? `We're sorry, but data is not available for ${listOfCountries[local]} at this time.` :
                                            <p>Showing results for {listOfCountries[local]}</p>}
                                    </>
                                }

                                <div className='where-to-watch'>
                                    {!!movie.providers?.results?.[local]?.flatrate &&
                                        <div>
                                            <h6>Stream</h6>
                                            <ul className='dashed'>
                                                {movie.providers?.results?.[local]?.flatrate?.map(results =>
                                                    <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                                )}
                                            </ul>
                                        </div>
                                    }

                                    {!!movie.providers?.results?.[local]?.buy && <div>
                                        <h6>Buy</h6>
                                        <ul className='dashed'>
                                            {movie.providers?.results?.[local]?.buy?.map(results =>
                                                <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                            )}
                                        </ul>
                                    </div>
                                    }

                                    {!!movie.providers?.results?.[local]?.rent && <div>
                                        <h6>Rent</h6>
                                        <ul className='dashed'>
                                            {movie.providers?.results?.[local]?.rent?.map(results =>
                                                <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                            )}
                                        </ul>
                                    </div>
                                    }

                                    {!!movie.providers?.results?.[local]?.free && <div>
                                        <h6>Free</h6>
                                        <ul className='dashed'>
                                            {movie.providers?.results?.[local]?.free?.map(results =>
                                                <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                            )}
                                        </ul>
                                    </div>
                                    }

                                    {!!movie.providers?.results?.[local]?.ads && <div>
                                        <h6>Free With Ads</h6>
                                        <ul className='dashed'>
                                            {movie.providers?.results?.[local]?.ads?.map(results =>
                                                <li key={crypto.randomUUID()}>{results.provider_name}</li>
                                            )}
                                        </ul>
                                    </div>
                                    }
                                </div>
                            </div>

                            <Reviews reviews={movie.reviews} />

                            {!!movie?.credits?.cast?.length && <>
                                <h5 className='margin-bottom'>Cast</h5>

                                <div className='scroll-tray'>
                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-left'
                                            onClick={() => slider('scroll-tray-content', 'left')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                        </button>
                                    )}

                                    <div className='scroll-tray' id='scroll-tray-content'>
                                        {movie?.credits?.cast?.map(cast => (
                                            <a className='link' href={`/view/person/${cast.id}`} ref={ref}>
                                                <div className='holder'>
                                                    {cast.adult && <span className='material-symbols-outlined adult-content-warning'> explicit </span>}
                                                    <div className='gradient'></div>
                                                    {cast.profile_path == null ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/original${cast.profile_path}`} alt={cast.name} />}
                                                    <div className='overlay'>
                                                        <div className='subtitle'>
                                                            {cast.character && `${cast.character}`}
                                                        </div>
                                                        <div className='title'>{cast.name}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>

                                    {!inView && (
                                        <button
                                            id='slide'
                                            type='button'
                                            className='arrow-buttons arrow-right'
                                            onClick={() => slider('scroll-tray-content', 'right')}
                                        >
                                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                        </button>
                                    )}

                                </div>
                            </>}

                            <div>
                                {!!movie?.recommendations?.results?.length && <>
                                    <h5 className='margin-bottom'>You May Also Like</h5>
                                    <div className='scroll-tray'>
                                        {!inView && (
                                            <button
                                                id='slide'
                                                type='button'
                                                className='arrow-buttons arrow-left'
                                                onClick={() => slider('scroll-tray-recommended', 'left')}
                                            >
                                                <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                            </button>
                                        )}

                                        <div className='scroll-tray' id='scroll-tray-recommended'>
                                            {movie?.recommendations?.results?.map(results => (
                                                <a className='link' href={`/view/movie/${results.id}`} ref={ref} >
                                                    <div className='holder'>
                                                        <div className='gradient'></div>
                                                        {!results.poster_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${results.poster_path}`} alt={results.name} />}
                                                        <div className='overlay'>
                                                            <div className='subtitle'>
                                                                {!results.vote_average ? '' : `${results?.vote_average?.toFixed(1)}/10 — `}
                                                                {!results?.release_date ? '' : `${new Date(results?.release_date) < new Date() ? new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                            </div>
                                                            <div className='title'>{results.title}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))}
                                        </div>

                                        {!inView && (
                                            <button
                                                id='slide'
                                                type='button'
                                                className='arrow-buttons arrow-right'
                                                onClick={() => slider('scroll-tray-recommended', 'right')}
                                            >
                                                <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                            </button>
                                        )}
                                    </div>
                                </>}
                            </div>

                            <FsLightbox className='lightbox' toggler={galleryToggler} type='image' sources={gallery} showThumbsOnMount={true} thumbs={gallery} />
                            <FsLightbox toggler={videoToggler} sources={trailers} />

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
