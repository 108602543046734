import { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'

import CustomNavbar from '../Navbar/CustomNavbar'
import { countries, listOfCountries } from '../../Helpers/utils'
import Select from 'react-select'

export default function Country() {

    const [country, setCountry] = useState()
    const [success, setSuccess] = useState(false)
    const local = localStorage.getItem('selected-country')

    useEffect(() => {
        document.title = `Select Your Country - Reel`
    }, [])

    function handleSubmit(e) {
        e.preventDefault()
        window.localStorage.setItem('selected-country', country)
        if (country !== undefined) {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #02FF7F',
            backgroundColor: 'transparent',
            borderRadius: '0px',
            boxShadow: '#6366F1',
            transition: 'box-shadow 0.1s ease-in-out',
            textAlign: 'left',
            "&:hover": {
                border: '1px solid #02FF7F',
            },
        }),

        placeholder: (provided) => ({
            ...provided,
            fontSize: '13px',
            fontWeight: '200',
            color: '#FFF',
            textAlign: 'left',
        }),

        valueContainer: (provided) => ({
            ...provided,
            padding: '0',
            marginLeft: '10px',
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),

        input: (provided) => ({
            ...provided,
            fontWeight: 200,
            fontSize: '13px',
            color: '#FFF',
        }),

        singleValue: (provided) => ({
            ...provided,
            fontWeight: 200,
            fontSize: '13px',
            color: '#FFF'
        }),

        option: (provided) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: '#333',
            color: '#02FF7F',
            textAlign: 'left',
            fontWeight: 200,
            "&:hover": {
                backgroundColor: '#F6FCFF',
                color: '#333'
            },
        }),

        menu: (provided) => ({
            ...provided,
            borderRadius: '0',
            backgroundColor: '#333',
        }),

        dropdownIndicator: base => ({
            ...base,
            color: '#F5F3F4',
            "&:hover": {
                color: '#F5F3F4',
            },
        })
    }

    if (!success) {
        return (
            <>

                <CustomNavbar />
                <div className='country-selection'>
                    <h1>Select Your Country</h1>
                    <p>By sharing your location with us, we can offer you more tailored and relevant content.</p>
                    <Form onSubmit={handleSubmit}>
                        <Select className='select' options={countries} onChange={e => setCountry(e.value)} styles={customStyles} />
                        <Button type='submit' className='custom-button rectangle'>Submit</Button>
                    </Form>
                </div>
            </>
        )
    } else {
        return (
            <>
                <CustomNavbar />
                <div className='country-selection'>
                    <h1>Hello, {listOfCountries[local]}.</h1>
                    <h3>You have successfully set your country!</h3>
                    <p>We will now show you localized content for {listOfCountries[local]}.</p>
                </div>
            </>
        )
    }
}