// React
import { useEffect, useState, useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import pluralize from 'pluralize'
import Modal from 'react-bootstrap/Modal'

// Custom Import
import ReactPlayer from 'react-player'

// Firebase
import { auth } from '../../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import useFavoritesStore from '../../Store/useFavoritesStore'

// SafeSearch Settings and Country Management
const safeSearch = localStorage.getItem('Watchresults_SafeSearch');

export default function ContentModal(props) {
    const [results, setResults] = useState([]);
    const [isMuted, setIsMuted] = useState(true);

    const fetchResults = useCallback(async () => {
        const response = await fetch(`https://api.skuflic.net/${props.url}/${props.data.id}?include_adult=${safeSearch}&append_to_response=content_ratings,external_ids,videos,similar,credits,recommendations,images,release_dates,releases&include_image_language=en,null`, { cache: 'no-cache' });
        const data2 = await response.json();
        setResults(data2);
    }, []);

    // Favorites
    const { addToFavorites, isFavorite, removeFromFavorites } = useFavoritesStore();

    const fetchInitialFavorites = useFavoritesStore((state) => state.fetchInitialFavorites);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchInitialFavorites();
            }
        });

        return () => unsubscribe();
    }, [fetchInitialFavorites]);

    const toggleMute = () => {
        setIsMuted(prevIsMuted => !prevIsMuted);
    };

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    const playerRef = useRef(null);
    const handleFullscreen = () => {
        const playerElement = playerRef.current.getInternalPlayer().getIframe();
        if (playerElement.requestFullscreen) {
            playerElement.requestFullscreen();
        } else if (playerElement.mozRequestFullScreen) { // Firefox
            playerElement.mozRequestFullScreen();
        } else if (playerElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            playerElement.webkitRequestFullscreen();
        } else if (playerElement.msRequestFullscreen) { // IE/Edge
            playerElement.msRequestFullscreen();
        }
    };

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    return (
        <>
            <Modal show={() => props.closeModal()} onHide={() => props.closeModal()} dialogClassName='my-modal' centered>
                <div className='holder'>
                    {results?.videos?.results[0]?.key == null ? <img src={`https://image.tmdb.org/t/p/w1280${props.data.backdrop_path}`} className='background' alt={props.data.name ?? props.data.title} /> : <div className='background'>
                        <ReactPlayer
                            allowFullScreen
                            ref={playerRef}
                            url={`https://www.youtube.com/watch?v=${results?.videos?.results[0]?.key}`}
                            playing
                            loop
                            muted={isMuted}
                            width='auto'
                            height='388px'
                        />
                        <div className='overlay'></div>
                    </div>}

                    <div className='close'>
                        <span className='material-symbols-outlined icon' onClick={() => props.closeModal()}> close </span>
                    </div>
                    {!!results?.images?.logos[0]?.file_path && <img src={`https://image.tmdb.org/t/p/w500${results?.images?.logos[0]?.file_path}`} alt={results.name ?? results.title} className='logo' />}
                </div>
                <div className='custom-container'>
                    <div className='flexbox-holder-main'>
                        <div className='flex-item-1'>
                            <ul className='content-short-overview'>
                                {!!results.vote_average && <li>{results.vote_average?.toFixed(1)}/10</li>}
                                {!!results.genres && <li>{results.genres?.map((genre, index) => <>{(index ? ', ' : '') + genre.name}</>)}</li>}
                            </ul>
                        </div>
                        <div className='flex-item-2'>
                            <div className='buttons'>
                                <div>
                                    <span
                                        className='material-symbols-outlined'
                                        onClick={isFavorite(props.data) ? () => removeFromFavorites(props.data) : () => addToFavorites(props.data, props.url)}
                                    >
                                        {isFavorite(props.data) ? 'heart_minus' : 'favorite'}
                                    </span>
                                    <p>{isFavorite(props.data) ? 'Unfavorite' : 'Favorite'}</p>
                                </div>

                                <div>
                                    <a href={`view/${props.url}/${props.data.id}`}><span className='material-symbols-outlined'>keyboard_arrow_right</span></a>
                                    <p>Explore</p>
                                </div>

                                <div>
                                    <span className='material-symbols-outlined' onClick={toggleMute}>{isMuted ? 'volume_up' : 'no_sound'}</span>
                                    <p>{isMuted ? 'Unmute' : 'Mute'}</p>
                                </div>

                                <div>
                                    <span className='material-symbols-outlined' onClick={handleFullscreen}>aspect_ratio</span>
                                    <p>Fullscreen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='details content-tagline'>{results.tagline}</p>
                    <div className='flexbox-holder-content-description'>
                        <div className='flex-item-1'>
                            <p>{props.data.overview}</p>
                        </div>
                        <div className='flex-item-2'>
                            <p>{pluralize('Genre', results.genres?.length)}: <span>{!!results.genres && results.genres?.map((genre, index) => <span key={genre.id}>{(index ? ', ' : '') + genre.name}</span>)}</span></p>

                            {results?.credits?.cast && <p>Cast: <span>{results?.credits?.cast?.slice(0, 5).map((cast, index) => (
                                <>
                                    {index ? ', ' : ''}
                                    <a key={index} href={`view/person/${cast.id}`}>
                                        {cast.name}
                                    </a>
                                </>
                            ))}
                            </span></p>}

                            {!!results?.created_by && <p>Created by: <span>{results?.created_by?.slice(0, 5).map((created_by, index) => (
                                <>
                                    {index ? ', ' : ''}
                                    <a key={index} href={`view/person/${created_by.id}`}>
                                        {created_by.name}
                                    </a>
                                </>
                            ))}
                            </span></p>}

                            {!!results.networks && <p>{pluralize('Network', results.networks?.length)}: {results.networks?.slice(0, 5).map((networks, index) => <span key={crypto.randomUUID()}>{(index ? ', ' : '') + networks.name}</span>)}</p>}
                        </div>
                    </div>
                    {/* <div className='no-overflow'>
                        {!!results?.recommendations?.results?.length && <>
                            <h1>You May Also Like</h1>

                            <div className='scroll-tray'>
                                {!inView && (
                                    <button
                                        id='slide'
                                        type='button'
                                        className='arrow-buttons arrow-left'
                                        onClick={() => slider('scroll-tray-recommended', 'left')}
                                    >
                                        <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                    </button>
                                )}

                                <div className='scroll-tray' id='scroll-tray-recommended'>
                                    {results?.recommendations?.results?.filter((poster_path) => poster_path.poster_path != null).map(results => (
                                        <a href={`/view/${props.url}/${results.id}`} ref={ref}>
                                            <div className='holder'>
                                                <div className='gradient'></div>
                                                {!results.poster_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${results.poster_path}`} alt={results.name} />}
                                                <div className='overlay'>
                                                    <div className='subtitle'>
                                                        {!results.vote_average ? '' : `${results?.vote_average?.toFixed(1)}/10 — `}
                                                        {!results?.release_date ? '' : `${new Date(results?.release_date) < new Date() ? new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                        {!results?.first_air_date ? '' : `${new Date(results?.first_air_date) < new Date() ? new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.first_air_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                    </div>
                                                    <div className='title'>{results.title ?? results.name}</div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>

                                {!inView && (
                                    <button
                                        id='slide'
                                        type='button'
                                        className='arrow-buttons arrow-right'
                                        onClick={() => slider('scroll-tray-recommended', 'right')}
                                    >
                                        <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                    </button>
                                )}
                            </div>

                        </>}
                    </div> */}
                </div>
            </Modal >
        </>
    );
}