// React
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

// Custom Functions
import { CustomNavbar, Rate } from '../../index.js'
import { slider, time_convert, dateFormatting_YMD, number_formatter } from '../../../Helpers/utils.js'

// Custom Imports
import Loading from '../../../Assets/Images/loading.svg'
import Image from '../../Image/Image'
import { NotFound } from '../../../index.js'

export default function Episodes(props) {
    const { tvID, seasonID, episodeID } = useParams()

    // State management for content
    const [episode, setEpisode] = useState([]);
    const [isLoaded, setLoaded] = useState(false);

    // SafeSearch Settings and Country Management
    const safeSearch = localStorage.getItem('safe-search')
    const showName = localStorage.getItem('selected-show') ?? ''

    useEffect(() => {
        const fetchEpisodeData = async () => {
            try {
                // Construct episode details URL
                const episodeUrl = `https://api.skuflic.net/tv/${tvID}/season/${seasonID}/episode/${episodeID}?include_adult=${safeSearch}&append_to_response=videos,similar,credits,recommendations,images,release_dates,content_ratings,external_ids`;

                // Fetch episode details with caching
                const episodeResponse = await fetch(episodeUrl, {
                    cache: 'force-cache',  // Force cache usage
                    headers: {
                        'Cache-Control': 'max-age=3600',  // Cache for 1 hour (adjust as needed)
                    },
                });
                if (!episodeResponse.ok) {
                    throw new Error('Failed to fetch episode details');
                }
                const episodeDetails = await episodeResponse.json();

                // Fetch ratings with caching
                const ratingResponse = await fetch(`https://ratings.skuflic.net?id=${episodeDetails.external_ids.imdb_id}`, {
                    cache: 'force-cache',  // Force cache usage
                    headers: {
                        'Cache-Control': 'max-age=3600',  // Cache for 1 hour (adjust as needed)
                    },
                });
                if (!ratingResponse.ok) {
                    throw new Error('Failed to fetch ratings');
                }
                const ratingData = await ratingResponse.json();

                // Update state with episode details and ratings
                setEpisode({
                    ...episodeDetails,
                    rating: ratingData,
                });

                // Set loaded state to true
                setLoaded(true);
            } catch (error) {
                console.error('Error fetching episode data:', error);
                // Optionally handle error state or display error message
            }
        };

        fetchEpisodeData();
    }, [episodeID, safeSearch, seasonID, tvID]);



    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    document.title = episode?.name ? `${episode.name} - S${seasonID}E${episode.episode_number} ${showName && `- ${showName}`} - Reel` : `Reel`

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    if (!isLoaded) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else if (!episodeID) {
        return <NotFound />
    } else {
        return (
            <>
                <CustomNavbar />
                <div className='holder-container'>

                    {episode?.still_path && <div className='splash'>
                        <Image title={episode.name} id='small' lowResSrc={`https://image.tmdb.org/t/p/w780${episode?.still_path}`} highResSrc={`https://image.tmdb.org/t/p/original${episode?.still_path}`} />
                    </div>}

                    <div className='custom-container'>
                        <h3>{showName && `${showName}: `} {episode.name}</h3>
                        <p className='details'>{episode.tagline}</p>

                        {!episode?.overview ? '' :
                            <div className='content-overview'>
                                <p className={expandedItem === episode.id ? 'read-less' : 'read-more' && episode.overview.length < 300 ? null : 'read-more'}>{episode.overview}</p>
                                {episode.overview.length > 300 && (
                                    <button onClick={() => handleToggle(episode.id)} className='read-more-button'>
                                        {expandedItem === episode.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        }

                        <div className='details episode'>
                            <div>
                                <h5>Episode Details</h5>
                                <p>Season {seasonID}, Episode {episodeID}</p>

                                {!episode?.runtime ? '' :
                                    <div>
                                        <h5>Episode Length</h5>
                                        <p>{time_convert(episode?.runtime)}</p>
                                    </div>
                                }

                                {!episode?.air_date ? '' :
                                    <div>
                                        <h5>Air date</h5>
                                        <p>{new Date(episode.air_date).toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                                    </div>
                                }

                                {!episode.vote_average && !episode?.rating?.Ratings?.length ? '' : <>
                                    <h5>Audience rating</h5>
                                    <p>What do others think of {episode.name}?</p>
                                </>
                                }

                                <div className='ratings'>
                                    {!episode.vote_average ? '' :
                                        <div>
                                            <h6 className='TheMovieDatabase'>The Movie Database</h6>
                                            <p>{episode.vote_average?.toFixed(1)}/10 ({number_formatter(episode.vote_count, 1)})</p>
                                        </div>
                                    }
                                    {episode?.rating?.Ratings?.map(result =>
                                        <div key={crypto.randomUUID()}>
                                            <h6 className={`${result.Source?.replace(/\s/g, '')}`}>{result.Source}</h6>
                                            <p id={`${result.Source?.replace(/\s/g, '')}`}>{result.Value}</p>
                                        </div>
                                    )}
                                </div>

                                <div className='rate'>
                                    <h5>Your Rating</h5>
                                    <p>What do you think of {episode.name}?</p>
                                    <Rate type='tv' id={`${tvID}/season/${seasonID}/episode/${episodeID}`} name={episode.name} />
                                </div>

                                {!episode?.guest_stars?.length ? '' : <>
                                    <h5 className='margin-bottom'>Guest Stars</h5>

                                    <div className='scroll-tray'>
                                        {!inView && (
                                            <button
                                                id='slide'
                                                type='button'
                                                className='arrow-buttons arrow-left'
                                                onClick={() => slider('scroll-tray-guest-stars', 'left')}
                                            >
                                                <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                            </button>
                                        )}

                                        <div className='scroll-tray ' id='scroll-tray-guest-stars'>
                                            {episode.guest_stars?.map(guest_stars => (
                                                <div key={guest_stars.id} ref={ref}>
                                                    <a className='link' href={`/view/person/${guest_stars.id}`} key={guest_stars.id}>
                                                        <div className='holder'>
                                                            <div className='gradient'></div>
                                                            {!guest_stars.profile_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${guest_stars.profile_path}`} alt={guest_stars.name} />}
                                                            <div className='overlay'>
                                                                <div className='subtitle'>
                                                                    {guest_stars.character}
                                                                </div>
                                                                <div className='title'>{guest_stars.name}</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>

                                        {!inView && (
                                            <button
                                                id='slide'
                                                type='button'
                                                className='arrow-buttons arrow-right'
                                                onClick={() => slider('scroll-tray-guest-stars', 'right')}
                                            >
                                                <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                            </button>
                                        )}
                                    </div>

                                </>}

                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}
