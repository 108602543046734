export default function SkeletonLoader(props) {
    return (
        <div>
            <div className={`holder skeleton-holder ${props.size}`}>
                <div className='skeleton gradient'></div>
                <div className='skeleton overlay'>
                    <div className='skeleton subtitle'>
                    </div>
                    <div className='skeleton title'></div>
                </div>
            </div>
        </div>
    );
};