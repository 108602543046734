import { useState, useEffect } from 'react'
import CustomNavbar from '../Navbar/CustomNavbar'
import { Button } from 'react-bootstrap'

// Firebase
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../firebase'
import { errorNotification } from '../../Helpers/utils'

export default function SafeSearch() {
    const [success, setSuccess] = useState(false)
    const safeSearchSettings = localStorage.getItem('safe-search')

    useEffect(() => {
        document.title = `SafeSearch - Reel`
    }, [])

    function handleChange() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                if (localStorage.getItem('safe-search') !== 'true') {
                    localStorage.setItem('safe-search', 'true')
                    setSuccess(true)
                } else {
                    localStorage.setItem('safe-search', 'false')
                    setSuccess(true)
                }
            } else {
                errorNotification('You must be logged in to perform this action. Please log in and try again.')
            }
        })
    }

    if (!success) {
        return (
            <>
                <CustomNavbar />
                <div className='safe-search'>
                    <h1>Filter explicit results using SafeSearch</h1>
                    <p>The SafeSearch setting filters explicit results, like pornography, to prevent them from appearing in your search results. <br />While no filter is 100% accurate, turning this setting on helps you avoid explicit content when you search.</p>
                    <p>Keep in mind that if you delete your cookies or local storage, your SafeSearch settings could get reset.</p>
                    <Button type='submit' onClick={handleChange} className='custom-button'>{safeSearchSettings === 'true' ? 'Turn ON SafeSearch' : 'Turn OFF SafeSearch'}</Button>
                </div>
            </>
        )
    } else {
        return (
            <>
                <CustomNavbar />
                <div className='safe-search'>
                    <h1>{safeSearchSettings === 'true' ? 'SafeSearch has been turned off' : 'SafeSearch has been turned on'}</h1>
                    <p>
                        From now on, we will {safeSearchSettings === 'true' ? 'show you' : 'no longer show'} you age-restricted content in our search results, and across <strong>Reel</strong>.
                        <br />
                        You can always come back here and change your preferences. Remember, our <a href='/servicesagreement' target='_blank' rel='noreferrer'>Services Agreement</a> still apply.
                    </p>
                </div>
            </>
        )
    }
}