import { useState } from 'react';
import { useInView } from 'react-intersection-observer'

// Helpers
import { dateFormatting_YMD, slider } from '../../Helpers/utils'

// Rate Component
import Rate from '../Rate/Rate'

export default function Reviews(props) {
    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    return (
        <div>
            {!props.reviews?.results.length ? null : <>
                <h5 className='margin-bottom'>Ratings and Reviews</h5>


                <div className='scroll-tray'>
                    {!inView && (
                        <button
                            id='slide'
                            type='button'
                            className='arrow-buttons arrow-left'
                            onClick={() => slider('scroll-tray-reviews', 'left')}
                        >
                            <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                        </button>
                    )}

                    <div className='scroll-tray reviews' id='scroll-tray-reviews'>
                        {props.reviews?.results?.map((results) => (
                            <div key={results.id} ref={ref} className='holder'>
                                {!!results.author_details.rating && <Rate initialValue={results.author_details.rating} readonly={true} />}
                                <p className='author'>
                                    <b>{results.author_details.username}<span className='material-symbols-outlined verified'> verified </span></b>
                                    <span className='date'>{new Date(results.created_at).toLocaleDateString('en-US', dateFormatting_YMD)}</span>
                                </p>
                                <p className={expandedItem === results.id ? 'review read-less' : 'review read-more' && results.content.length < 150 ? 'review' : 'review read-more'}>{results.content}</p>
                                {results.content.length > 150 && (
                                    <button onClick={() => handleToggle(results.id)} className='read-more-button'>
                                        {expandedItem === results.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>

                    {!inView && (
                        <button
                            id='slide'
                            type='button'
                            className='arrow-buttons arrow-right'
                            onClick={() => slider('scroll-tray-reviews', 'right')}
                        >
                            <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                        </button>
                    )}
                </div>
            </>
            }
        </div >
    );
};
