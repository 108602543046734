// React
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Bootstrap
import { Navbar, Container } from 'react-bootstrap'


// Firebase Auth
import { auth } from '../../firebase'
import { GoogleAuthProvider, signInWithCredential, onAuthStateChanged } from 'firebase/auth'
import { authErrorCodes, errorNotification } from '../../Helpers/utils'

// Toastify
import { ToastContainer, toast } from 'react-toastify'

// Assets
import WatchLogo from '../../Assets/Images/logo.svg'
import Search from '../Search/Search'

// Google One Tap
import googleOneTap from 'google-one-tap'

export default function CustomNavbar(props) {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const navigate = useNavigate()

    // const [colorChange, setColorchange] = useState(true)
    // const changeNavbarColor = () => {
    //     if (window.scrollY >= 80) {
    //         setColorchange(false)
    //     } else {
    //         setColorchange(true)
    //     }
    // }
    // window.addEventListener('scroll', changeNavbarColor)

    const toastOptions = {
        position: 'top-right',
        autoClose: 1800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
    }

    useEffect(() => {
        const options = {
            client_id: process.env.REACT_APP_ONE_TAP_KEY,
            auto_select: true,
            cancel_on_tap_outside: true,
            context: 'use',
            ux_mode: 'popup',
        }

        window.onscroll = function () {
            const topButton = document.getElementById('top-button')
            topButton.style.display = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ? 'block' : 'none'
            document.querySelector('.navbar').classList[window.scrollY > 100 ? 'add' : 'remove']('nav-active')
        }

        function successRedirect() {
            toast.success('Connection succeeded. You will soon be redirected.', toastOptions)
            setTimeout(() => {
                navigate(0)
            }, 2500)
        }

        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                googleOneTap(options, (response) => {
                    const OAuthCredential = GoogleAuthProvider.credential(response.credential)
                    signInWithCredential(auth, OAuthCredential)
                        .then(successRedirect())
                        .catch((error) => errorNotification(authErrorCodes[error.code]))
                })
            }
        })
    }, [navigate])

    const toggleNavigation = () => {
        setIsNavOpen(!isNavOpen)
    }

    //className={colorChange ? 'navbar colorChange' : 'navbar'}
    return (
        <>
            <Navbar sticky='top'>
                <ToastContainer
                    position='top-right'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTopcloseOnClickrtl={false}
                    pauseOnFocusLossdraggablepauseOnHovertheme='dark'
                />
                <Container>
                    <Navbar.Brand href='/'>
                        <img src={WatchLogo} alt={`Reel`} id='logo' />
                    </Navbar.Brand>

                    <Search />
                    <a href='/account'>
                        <span className='material-symbols-outlined nav-account'> account_circle </span>
                    </a>
                    <a href='/account/favorites'>
                        <span className='material-symbols-outlined nav-favorites'> favorite </span>
                    </a>
                    <span onClick={toggleNavigation}>
                        <span className='material-symbols-outlined nav-menu'> menu </span>
                    </span>

                    <div id='customNavigation' className={`overlay ${isNavOpen ? 'open' : ''}`}>
                        <button className='main-button close-button' onClick={toggleNavigation}>
                            <span className='material-symbols-outlined'> close </span>
                        </button>
                        <div className='overlay-content'>
                            <div className='navigation-content'>
                                <div className='tv'>
                                    <h1>TV Shows</h1>
                                    <a href='/trending/tv'>Trending</a>
                                    <a href='/popular/tv'>Popular</a>
                                    <a href='/top-rated/tv'>Top rated</a>
                                    <a href='/airing-today/tv'>Airing today</a>
                                    <a href='/on_the_air/tv'>On The Air</a>
                                    <h2>By genre</h2>
                                    <a href='/discover/tv/genre/action-and-adventure'>Action & Adventure</a>
                                    <a href='/discover/tv/genre/comedy'>Comedy</a>
                                    <a href='/discover/tv/genre/crime'>Crime</a>
                                    <a href='/discover/tv/genre/documentaries'>Documentary</a>
                                    <a href='/discover/tv/genre/drama'>Drama</a>
                                    <a href='/discover/tv/genre/family'>Family</a>
                                    {/* <a href='/discover/tv/genre/kids'>Kids</a> */}
                                    <a href='/discover/tv/genre/mystery'>Mystery</a>
                                    <a href='/discover/tv/genre/reality'>Reality</a>
                                    <a href='/discover/tv/genre/romance'>Romance</a>
                                    <a href='/discover/tv/genre/science-fiction-and-fantasy'>Sci-Fi & Fantasy</a>
                                    <h2>By network</h2>
                                    <a href='/discover/tv/network/amc'>AMC+</a>
                                    <a href='/discover/tv/network/apple'>Apple TV+</a>
                                    <a href='/discover/tv/network/disney'>Disney+</a>
                                    <a href='/discover/tv/network/fx'>FX</a>
                                    <a href='/discover/tv/network/hbo'>HBO</a>
                                    <a href='/discover/tv/network/hulu'>Hulu</a>
                                    <a href='/discover/tv/network/netflix'>Netflix</a>
                                    <a href='/discover/tv/network/paramount'>Paramount+</a>
                                    <a href='/discover/tv/network/showtime'>Showtime</a>
                                </div>
                                <div className='movies'>
                                    <h1>Movies</h1>
                                    <a href='/trending/movies'>Trending</a>
                                    <a href='/popular/movies'>Popular</a>
                                    <a href='/top-rated/movies'>Top rated</a>
                                    <a href='/upcoming/movies'>Upcoming</a>
                                    <a href='/now-playing/movies'>In theatres</a>
                                    <h2>By genre</h2>
                                    <a href='/discover/movies/genre/action'>Action</a>
                                    <a href='/discover/movies/genre/adventure'>Adventure</a>
                                    <a href='/discover/movies/genre/comedy'>Comedy</a>
                                    <a href='/discover/movies/genre/crime'>Crime</a>
                                    <a href='/discover/movies/genre/documentaries'>Documentary</a>
                                    <a href='/discover/movies/genre/drama'>Drama</a>
                                    <a href='/discover/movies/genre/horror'>Horror</a>
                                    <a href='/discover/movies/genre/mystery'>Mystery</a>
                                    <a href='/discover/movies/genre/romance'>Romance</a>
                                    <a href='/discover/movies/genre/science-fiction'>Science Fiction</a>
                                    <a href='/discover/movies/genre/thriller'>Thriller</a>
                                </div>
                                <div className='people'>
                                    <h1>People</h1>
                                    <a href='/trending/people'>Trending</a>
                                    <a href='/popular/people'>Popular</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </>
    )
}
