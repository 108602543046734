import { useState } from 'react'

const useImageOnLoad = () => {

    const [isLoaded, setIsLoaded] = useState(false)
    const handleImageOnLoad = () => setIsLoaded(true)

    const transitionStyles = {
        lowRes: {
            opacity: isLoaded ? 0 : 1,
            // filter: 'blur(100px)',
            transition: 'opacity 650ms ease-out 50ms',
        },
        highRes: {
            opacity: isLoaded ? 1 : 0,
            // filter: 'blur(100px)',
            transition: 'opacity 650ms ease-in 50ms',
        },
    }

    return { handleImageOnLoad, transitionStyles }

}

export default useImageOnLoad