// React
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

//Custom Functions
import { CustomNavbar, Share } from '../index.js'

// Assets
import Loading from '../../Assets/Images/loading.svg'
import Image from '../Image/Image'

// Custom Imports
import { dateFormatting_Y, slider, dateFormatting_YMD } from '../../Helpers/utils.js'

export default function Collection() {
    let params = useParams()

    // State management for content
    const [coll, setCollection] = useState([])

    // Other state management
    const [isLoaded, setLoaded] = useState(false)

    // API URL
    const url = `https://api.skuflic.net/collection/${params.collectionID}?append_to_response=images&include_image_language=en,null`

    function getCollection() {
        fetch(url, { cache: 'force-cache' })
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCollection(data)
                setLoaded(true)
            })
    }

    useEffect(() => {
        getCollection()
    }, [params.collectionID])

    document.title = `${!coll.name ? `Reel` : `${coll.name} - Reel`}`

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (itemId) => {
        setExpandedItem((prevItem) => (prevItem === itemId ? null : itemId));
    };

    const { ref, inView } = useInView({
        threshold: [1.0],
        rootMargin: '0px -100px 0px 0px',
    });

    if (!isLoaded) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /></div></>
    } else {
        return (
            <>
                <CustomNavbar />
                <div className='holder-container'>
                    <div className='splash'>
                        <Image title={coll.name} id='small' lowResSrc={`https://image.tmdb.org/t/p/w780${coll?.backdrop_path}`} highResSrc={`https://image.tmdb.org/t/p/w1920_and_h1080_multi_faces${coll?.backdrop_path}`} />
                    </div>
                    <div className='custom-container'>

                        <h3 className='content-title'>{coll.name}</h3>

                        {!!coll.overview &&
                            <div className='content-overview'>
                                <p className={expandedItem === coll.id ? 'read-less' : 'read-more' && coll.overview.length < 300 ? null : 'read-more'}>{coll.overview}</p>
                                {coll.overview.length > 300 && (
                                    <button onClick={() => handleToggle(coll.id)} className='read-more-button'>
                                        {expandedItem === coll.id ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        }

                        <h5>Share</h5>
                        <Share url={window.location.href} title={coll.name} />

                        {!coll?.parts?.length ? '' : <>
                            <h5 className='margin-bottom'>From This Collection</h5>

                            <div className='scroll-tray'>
                                {!inView && (
                                    <button
                                        id='slide'
                                        type='button'
                                        className='arrow-buttons arrow-left'
                                        onClick={() => slider('scroll-tray-movies', 'left')}
                                    >
                                        <span className='material-symbols-outlined'> keyboard_arrow_left </span>
                                    </button>
                                )}

                                <div className='scroll-tray' id='scroll-tray-movies'>
                                    {coll?.parts?.map(results => (
                                        <div key={results.id} ref={ref}>
                                            <a className='link' href={`/view/movie/${results.id}`}>
                                                <div className='holder'>
                                                    <div className='gradient'></div>

                                                    {!results.poster_path ? <span className='material-symbols-outlined image not-available'> hide_image </span> : <img className='image' src={`https://image.tmdb.org/t/p/w780${results.poster_path}`} alt={results.name} />}

                                                    <div className='overlay'>
                                                        <div className='subtitle'>
                                                            {!results.vote_average ? '' : `${results?.vote_average?.toFixed(1)}/10 — `}
                                                            {!results?.release_date ? '' : `${new Date(results?.release_date) < new Date() ? new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_Y) : `Releases ${new Date(results?.release_date).toLocaleDateString('en-US', dateFormatting_YMD)}`}`}
                                                        </div>
                                                        <div className='title'>{results.title}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>

                                {!inView && (
                                    <button
                                        id='slide'
                                        type='button'
                                        className='arrow-buttons arrow-right'
                                        onClick={() => slider('scroll-tray-movies', 'right')}
                                    >
                                        <span className='material-symbols-outlined'> keyboard_arrow_right </span>
                                    </button>
                                )}

                            </div>
                        </>}

                    </div>
                </div>
            </>
        )
    }
}
