// Zustand
import create from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

// Firebase
import { deleteDoc, doc, getDoc, setDoc, getDocs, collection, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';

// Helper Functions
import { errorNotification, successNotification } from '../Helpers/utils';

const useFavoritesStore = create(
    persist(
        (set, get) => ({
            favorites: [],
            fetchInitialFavorites: async () => {
                try {
                    const userCollectionRef = collection(db, auth.currentUser.uid);
                    const querySnapshot = await getDocs(userCollectionRef);

                    const favorites = querySnapshot.docs.map((doc) => doc.id);
                    set({ favorites });

                    // Real-time listener for favorites changes
                    onSnapshot(userCollectionRef, (snapshot) => {
                        const updatedFavorites = snapshot.docs.map((doc) => doc.id);
                        set({ favorites: updatedFavorites });
                    });
                } catch (error) {
                    errorNotification(error);
                }
            },

            addToFavorites: async (content, sentType) => {
                if (!auth?.currentUser?.uid) {
                    errorNotification(`You must be signed in to add ${content.title ?? content.name} to favorites.`);
                    return;
                }
                try {
                    const docRef = doc(db, auth.currentUser.uid, `${content.id}`);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists() || get().favorites.includes(content.id)) {
                        errorNotification(`${content.title ?? content.name} has already been added to favorites.`);
                    } else {
                        await setDoc(docRef, {
                            reference: content.id,
                            name: content.title ?? content.name,
                            type: sentType,
                            image: content?.poster_path ?? content?.profile_path,
                            adult: content?.adult,
                            timestamp: serverTimestamp(),
                        }, { merge: true });
                        set((state) => ({
                            favorites: [...state.favorites, content.id],
                        }));
                        successNotification(`${content.title ?? content.name} has been added to favorites.`);
                    }
                } catch (error) {
                    errorNotification(error.message);
                }
            },

            removeFromFavorites: async (content) => {
                if (!auth?.currentUser?.uid) {
                    errorNotification(`You must be signed in to remove ${content.title ?? content.name} favorite.`);
                    return;
                }
                try {
                    await deleteDoc(doc(db, auth.currentUser.uid, content?.id?.toString()));
                    set((state) => ({
                        favorites: state.favorites.filter((item) => item !== content.id),
                    }));
                    successNotification(`${content.title ?? content.name} has been removed from favorites.`);
                } catch (error) {
                    errorNotification(error.message);
                }
            },

            isFavorite: (content) => get().favorites.includes(content.id.toString()),

            clearFavorites: () => {
                localStorage.removeItem('favorites');
                set({ favorites: [] });
            },
        }),
        {
            name: 'favorites',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useFavoritesStore;
