// CSS Styles
import './Styles/App.scss';

// Components
import { CustomNavbar, AnimatedList, AccordionItem } from './Components';

// React Components & Third Party Libs
import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { faqs } from './Helpers/utils';

import Carousel from 'react-bootstrap/Carousel';
import pluralize from 'pluralize';

export default function App() {
  const [clicked, setClicked] = useState('0');

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked('0');
    }
    setClicked(index);
  };

  const [results, setResults] = useState([]);

  const CACHE_DURATION = 3600000; // 1 hour in milliseconds
  const cache = new Map();

  const fetchDetailsById = async (id, mediaType) => {
    const cacheKey = `${mediaType}-${id}`;
    const cachedData = cache.get(cacheKey);
    const now = Date.now();

    if (cachedData && now - cachedData.timestamp < CACHE_DURATION) {
      return cachedData.data;
    }

    try {
      const response = await fetch(
        `https://api.skuflic.net/${mediaType}/${id}?include_adult=${localStorage.getItem(
          'safe-search'
        )}&append_to_response=videos,reviews,similar,credits,recommendations,images,release_dates,releases&include_image_language=en,null`,
        {
          cache: 'force-cache', // Force cache usage
          headers: {
            'Cache-Control': 'max-age=3600', // Cache for 1 hour
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch details by ID');
      }

      const data = await response.json();
      cache.set(cacheKey, { data, timestamp: now });

      return data;
    } catch (error) {
      console.error('Error fetching details by ID:', error);
      throw error; // Rethrow the error to handle it where fetchDetailsById is called
    }
  };

  const fetchResults = useCallback(async () => {
    try {
      const response = await fetch(
        `https://api.skuflic.net/trending/all/week?include_adult=false&page=1&language=en-US&sort_by=vote_average.desc&include_image_language=en,null`,
        { cache: 'no-cache' }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch trending results');
      }

      const data = await response.json();

      // Fetch detailed info for each result
      const detailedResults = await Promise.all(
        data.results.map(async (item) => {
          const details = await fetchDetailsById(item.id, item.media_type);
          return { ...item, details };
        })
      );

      setResults(detailedResults);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  }, []);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  return (
    <>
      <Helmet>
        <meta
          property='og:description'
          content='Find all the information about your favorite movies, TV shows, actors, and celebrities - all in one place.'
        />
        <meta property='og:image' content='/assets/open_graph.jpg' />
        <meta property='og:url' content='https://reel.skuflic.com' />
        <meta property='og:site_name' content='Reel' />
        <meta
          property='og:title'
          content='Reel - Movies, TV Shows, Actors, Celebrities & More'
        />

        <meta
          name='twitter:title'
          content='Reel - Movies, TV Shows, Actors, Celebrities & More'
        />
        <meta
          name='twitter:description'
          content='Find all the information about your favorite movies, TV shows, actors, and celebrities - all in one place.'
        />
        <meta name='twitter:image' content='/assets/open_graph.jpg' />
        <meta
          name='twitter:card'
          content='Find all the information about your favorite movies, TV shows, actors, and celebrities - all in one place.'
        />
      </Helmet>
      <CustomNavbar />
      <Carousel pause='hover' fade controls={true}>
        {results
          ?.filter((backdrop_path) => backdrop_path.poster_path != null)
          .map((data) => (
            <Carousel.Item key={crypto.randomUUID()}>
              <div
                className='image'
                style={{
                  backgroundImage: `url(${`https://image.tmdb.org/t/p/original${
                    data.details?.images?.backdrops.reduce(
                      (max, item) =>
                        item.vote_average > max.vote_average ? item : max,
                      data.details?.images?.backdrops[0]
                    )?.file_path
                  }`})`,
                }}
              />

              <Carousel.Caption>
                <div className='flex intro'>
                  {!data.details?.images?.logos.reduce(
                    (max, item) =>
                      item.vote_average > max.vote_average ? item : max,
                    data.details?.images?.logos[0]
                  )?.file_path ? (
                    <h3>{data.details.title ?? data.details.name}</h3>
                  ) : (
                    <a href={`/view/${data.media_type}/${data.id}`}>
                      <img
                        src={`https://image.tmdb.org/t/p/w780${
                          data.details?.images?.logos.reduce(
                            (max, item) =>
                              item.vote_average > max.vote_average ? item : max,
                            data.details?.images?.logos[0]
                          ).file_path
                        }`}
                        alt={data.details.title ?? data.details.name}
                        className='logo'
                      />
                    </a>
                  )}
                </div>
                <div className='flex'>
                  <p className='overview'>
                    <span className='read-more'>{data.overview}</span>
                    <a
                      href={`/view/${data.media_type}/${data.id}`}
                      className='read-more-button'>
                      Read More
                    </a>
                  </p>
                  <div className='info'>
                    <p>
                      {pluralize('Genre', data?.details?.genres?.length)}:{' '}
                      <span>
                        {!!data.details.genres &&
                          data.details.genres?.map((genre, index) => (
                            <span key={genre.id}>
                              {(index ? ', ' : '') + genre.name}
                            </span>
                          ))}
                      </span>
                    </p>

                    {data?.details?.credits?.cast && (
                      <p>
                        Cast:{' '}
                        <span>
                          {data?.details?.credits?.cast
                            ?.slice(0, 5)
                            .map((cast, index) => (
                              <>
                                {index ? ', ' : ''}
                                <a key={index} href={`view/person/${cast.id}`}>
                                  {cast.name}
                                </a>
                              </>
                            ))}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>

      {/* <div className='homepage-holder'>
        <div id='hero'>
          <h3>
            Your <span>entertainment</span> hub. Anytime, anywhere.
          </h3>
          <p>
            Get ready to dive into a world of endless entertainment - all at
            your fingertips. Discover new favorites and rediscover old classics
            with <b className='green'>Reel</b>.
          </p>
          <a href='/account'>
            Start exploring
            <span className='material-symbols-outlined'> arrow_right_alt </span>
          </a>
        </div>
      </div> */}

      <AnimatedList link='movie' type='movie/upcoming' speed={70} />
      <AnimatedList link='tv' type='trending/tv/week' speed={100} />
      <AnimatedList link='movie' type='trending/movie/week' speed={70} />

      <div className='faq'>
        <h4>Frequently Asked Questions</h4>
        <ul className='accordion'>
          {faqs.map((faq, index) => (
            <AccordionItem
              onToggle={() => handleToggle(index)}
              active={clicked === index}
              key={index}
              faq={faq}
            />
          ))}
        </ul>
      </div>
    </>
  );
}
