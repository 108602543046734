// React
import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Turnstile } from '@marsidev/react-turnstile'

// Firebase
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

// Custom Functions
import { CustomNavbar } from '../../Components'
import { authErrorCodes, errorNotification } from '../../Helpers/utils'

// MailChecker
import MailChecker from 'mailchecker'

export default function PasswordReset() {
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(false)
    const [token, setToken] = useState()
    const reference = useRef()
    const auth = getAuth()

    const onPasswordReset = async (e) => {
        e.preventDefault()
        const response = await fetch(`https://turnstile.skuflic.net?rt=${token}`)
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    reference.current?.reset();
                }
                return data.success;
            });

        if (MailChecker.isValid(email) && response === true) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    setSuccess(true)
                })
                .catch((error) => {
                    errorNotification(authErrorCodes[error.code])
                })
        }
    }

    document.title = `Reset Your Password - Reel`

    if (success === false) {
        return (
            <>
                <div className='main'>
                    <CustomNavbar />
                    <div className='account'>
                        <h1>Reset Your Password</h1>
                        <p>Enter your <b>Reel</b> email address and we'll send you a link to reset your password.</p>
                        <div className='login-form'>
                            <div className='input-field'>
                                <div className='help-text'>EMAIL ADDRESS</div>
                                <input id='email-address' name='email' type='email' required className='field' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <button onClick={onPasswordReset} className='button'>Reset Password</button><br />
                            <p className='password-reset'><a href='/account/login'>Return to Sign In</a></p>
                        </div>
                        {<Turnstile
                            siteKey='0x4AAAAAAAM_D35F4o3pwJXW'
                            className='turnstile'
                            options={{
                                action: 'password-reset',
                                theme: 'dark',
                                size: 'normal',
                                language: 'en',
                                refreshExpired: 'auto',
                                appearance: 'interaction-only'
                            }}
                            onSuccess={setToken} ref={reference} />}
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='main'>
                    <CustomNavbar />
                    <div className='account'>
                        <h1>Email Sent 👋</h1>
                        <p>Check your {email} inbox for a password reset email. <br />If you don't get this email soon, contact Skuflic.com Support.</p>
                    </div>
                </div>
            </>
        )
    }
}