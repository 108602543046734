// React
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Custom Functions
import { CustomNavbar } from '../../Components'
import { authErrorCodes, dateFormatting_YM, dateFormatting_YMD, errorNotification, successNotification } from '../../Helpers/utils'
import Tabs from './Tabs'

// Firebase
import { onAuthStateChanged, signOut, deleteUser, linkWithPopup, unlink, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider, updateEmail, updatePassword, updateProfile, GithubAuthProvider } from 'firebase/auth'
import { auth, storage } from '../../firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

// Assets
import Loading from '../../Assets/Images/loading.svg'
import Google from '../../Assets/Images/google.svg'
import Twitter from '../../Assets/Images/twitter.svg'
import Microsoft from '../../Assets/Images/microsoft.svg'
import GitHub from '../../Assets/Images/github.svg'
import useFavoritesStore from '../../Store/useFavoritesStore'

const googleProvider = new GoogleAuthProvider()
const twitterProvider = new TwitterAuthProvider()
const microsoftProvider = new OAuthProvider('microsoft.com')
const githubProvider = new GithubAuthProvider()

export default function Account() {
    const navigate = useNavigate()

    // State Management
    const [user, setUser] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [rerender, setRerender] = useState(false)
    const [file, setFile] = useState('')
    const [percent, setPercent] = useState(0)

    const { clearFavorites } = useFavoritesStore()

    const userAccount = auth.currentUser

    const linkAccount = (props) => {
        linkWithPopup(auth.currentUser, props).then(() => {
            successNotification('Your account has been linked successfully.')
            setRerender(!rerender)
        }).catch((error) => {
            errorNotification(authErrorCodes[error.code])
        })
    }

    const unlinkAccount = (props) => {
        unlink(auth.currentUser, props).then(() => {
            successNotification('Your account has been unlinked successfully.')
            setRerender(!rerender)
        }).catch((error) => {
            errorNotification(authErrorCodes[error.code])
        })
    }

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                clearFavorites()
                navigate('/account/login')
            })
            .catch((error) => {
                errorNotification(authErrorCodes[error.code])
            })
    }

    const deleteProfilePicture = () => {
        updateProfile(auth.currentUser, { photoURL: '' })
            .then(() => {
                successNotification('Your profile picture has been deleted successfully.')
                setRerender(!rerender)
            }).catch((error) => {
                errorNotification(authErrorCodes[error.code])
            })
    }

    const deleteUserAccount = () => {
        deleteUser(userAccount)
            .then(() => {
                clearFavorites()
                successNotification(`Your account has been deleted successfully and will be redirected back to Reel shortly.`)
            })
            .catch((error) => {
                errorNotification(authErrorCodes[error.code])
            })
    }

    const [isDeletionVisible, setDeletionVisible] = useState(false)

    const showAccountDeletion = () => {
        setDeletionVisible(true)
    }

    const changePassword = () => {
        if ((password === confirmPassword) && (password !== '')) {
            updatePassword(user, password).then(() => {
                successNotification('You have successfully changed your password.')
            }).catch((error) => {
                errorNotification(authErrorCodes[error.code])
            })
        } else {
            errorNotification('Passwords do not match or passwords are empty.')
        }

    }

    const changeEmail = () => {
        if (email !== '') {
            updateEmail(auth.currentUser, email).then(() => {
                successNotification('You have successfully changed your email address. Please verify your new email address.')
                document.getElementById('email').value = ''
                setRerender(!rerender)
            }).catch((error) => {
                errorNotification(authErrorCodes[error.code])
            })
        } else {
            errorNotification('Email cannot be empty.')
        }

    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user)
            } else {
                navigate('/account/login')
            }
        })
    }, [linkAccount, unlinkAccount, changeEmail])

    function uploadPicture(e) {
        setRerender(!rerender)
        setFile(e.target.files[0])
        document.getElementById('upload-label').innerHTML = e.target.files[0].name
    }

    function handleUpload() {
        const metadata = {
            contentType: file.type,
            customMetadata: {
                email: userAccount.email,
                uploader: userAccount.displayName
            },
        }

        const storageRef = ref(storage, `/users/${userAccount.uid}/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file, metadata)

        if (file.type.match('image.*')) {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    )

                    // Update Progress
                    setPercent(percent)
                    document.querySelector('.uploading').style.visibility = 'visible'
                },
                (error) => errorNotification(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        updateProfile(auth.currentUser, {
                            photoURL: url
                        }).then(() => {
                            successNotification('Your profile picture has been changed successfully.')
                            setRerender(!rerender)
                            document.getElementById('upload-label').innerHTML = 'Choose a file'
                        }).catch((error) => {
                            errorNotification(error)
                        })
                    })
                    document.querySelector('.uploading').style.visibility = 'hidden'
                }
            )
        } else {
            errorNotification('Please select a valid file type.')
        }
    }

    document.title = `Account Management - Reel`

    if (!auth.currentUser) {
        return <><CustomNavbar /><div className='loading'><img src={Loading} alt='Loading' /><div className='error-placeholder' id='firebase-error'></div></div></>
    } else {
        return (
            <>
                <div className='main'>
                    <CustomNavbar />
                    <div className='account'>
                        <h1>Account Management</h1>
                        <p>Manage your info, privacy, and security to make <b>Reel</b> work better for you.</p>
                        <Tabs>
                            <div label='Your Account'>
                                {user.photoURL == null ? '' : <img src={user.photoURL} alt={user.displayName} className='user' />}
                                <p>Hello, {user.displayName}{user.emailVerified == true ? <span className='material-symbols-outlined verified'> verified </span> : ''}<br /> Welcome to your <b>Reel</b> account.</p>
                                <br />
                                <p><b>Member since</b> {new Date(user?.metadata?.creationTime)?.toLocaleDateString('en-US', dateFormatting_YM)}</p>
                                <p><b>Last sign in on</b> {new Date(user?.metadata?.lastSignInTime)?.toLocaleDateString('en-US', dateFormatting_YMD)}</p>
                                <br />
                                {/* <button onClick={() => navigate('/account/favorites')} className='button inverted'>Favorites</button> */}
                                <button onClick={handleLogout} className='button logout'>Logout</button>
                            </div>

                            <div label='Profile Picture'>
                                <p>You can choose a photo to set as your <b>Reel</b> profile picture.</p>
                                <p>You can upload images in PNG, JPG (preferred), and JPEG file formats up to 600 kilobytes.</p>
                                <div className='profile-picture-holder'>
                                    <div className='upload'>
                                        <input type='file' name='file' id='file' className='file' onChange={uploadPicture} />
                                        <label htmlFor='file' id='upload-label' className='button inverted'>Choose a file</label>
                                    </div>
                                    <button onClick={handleUpload} className='button'>Upload and Change</button>
                                    {!!auth.currentUser.photoURL &&
                                        <>
                                            <div className='separator'>OR</div>
                                            <button id='delete-account' className='button delete' onClick={deleteProfilePicture}>Delete Profile Picture</button>
                                        </>
                                    }
                                    <div className='uploading'>Uploading {percent}%</div>
                                </div>
                            </div>

                            <div label='Email Address'>
                                <p>You can use a different email address (username) to identify your <b>Reel</b> Account.</p>
                                <p>Just so you're aware, your current email address is {user?.email}.</p>
                                <div className='login-form'>
                                    <div className='input-field'>
                                        <div className='help-text'>NEW EMAIL ADDRESS</div>
                                        <input id='email' name='email' type='email' required className='field' onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <button onClick={changeEmail} className='button'>Change Email Address</button><br />
                                </div>
                            </div>

                            <div label='Password'>
                                <p>You can change your password for security reasons or reset it if you forget it.</p>
                                <p>If you're having trouble resetting your password or can't sign in to your account, contact support.</p>
                                <br />
                                <p>Protect your account with a unique password at least 8 characters long.</p>
                                <div className='login-form'>
                                    <div className='input-field'>
                                        <div className='help-text'>NEW PASSWORD</div>
                                        <input id='password' name='password' type='password' required className='field' onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className='input-field'>
                                        <div className='help-text'>CONFIRM NEW PASSWORD</div>
                                        <input id='password' name='password' type='password' required className='field' onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                    <button onClick={changePassword} className='button'>Change Password</button><br />
                                </div>
                            </div>

                            <div label='Social Login'>
                                <p className='help'>Social Login allows you to sign in using your existing account from Social Networks.<br /> If you'd like to connect or disconnect your <b>Reel</b> account with Social Networks, you may do so here.</p>

                                {user?.providerData?.map(data => data.providerId).includes('google.com') === true ? <button onClick={() => unlinkAccount('google.com')} className='button google'><img src={Google} alt='Unlink Google Account' />Unlink Google Account</button> : <button onClick={() => linkAccount(googleProvider)} className='button google'><img src={Google} alt='Link Google Account' />Link Google Account</button>}

                                {user?.providerData?.map(data => data.providerId).includes('twitter.com') === true ? <button onClick={() => unlinkAccount('twitter.com')} className='button twitter'><img src={Twitter} alt='Unlink X Account' />Unlink X Account</button> : <button onClick={() => linkAccount(twitterProvider)} className='button twitter'><img src={Twitter} alt='Link X Account' />Link X Account</button>}

                                {user?.providerData?.map(data => data.providerId).includes('microsoft.com') === true ? <button onClick={() => unlinkAccount('microsoft.com')} className='button microsoft'><img src={Microsoft} alt='Unlink Microsoft Account' />Unlink Microsoft Account</button> : <button onClick={() => linkAccount(microsoftProvider)} className='button microsoft'><img src={Microsoft} alt='Link Microsoft Account' />Link Microsoft Account</button>}

                                {user?.providerData?.map(data => data.providerId).includes('github.com') === true ? <button onClick={() => unlinkAccount('github.com')} className='button github'><img src={GitHub} alt='Unlink GitHub Account' /> Unlink GitHub Account</button> : <button onClick={() => linkAccount(githubProvider)} className='button github'><img src={GitHub} alt='Link GitHub Account' />Link GitHub Account</button>}
                            </div>

                            <div label='Delete Account'>
                                {!isDeletionVisible && (
                                    <>
                                        <p>You can delete your <b>Reel</b> Account at any time.</p>
                                        <p>If you change your mind, you will not be able to recover it.<br /><br />You'll lose all the data and content in that account, like favorites.</p>
                                        <button id='delete-account' className='button delete' onClick={showAccountDeletion}>Delete Account</button>
                                        <span className='disclaimer' id='delete-account-disclaimer'>Deleting your account is permanent. Proceed with caution.</span>
                                    </>
                                )}
                                {isDeletionVisible && (
                                    <>
                                        <p id='delete'>"Son, you know, once you start - there's no going back."<br />Once you do this, not even God can restore your account.
                                            <span className='confirm' onClick={deleteUserAccount}>Confirm Account Deletion</span>
                                        </p>
                                    </>
                                )}

                            </div>
                        </Tabs>
                    </div>
                </div>
            </>
        )
    }
}