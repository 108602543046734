import { EmailShareButton, FacebookShareButton, RedditShareButton, TelegramShareButton, TumblrShareButton, TwitterShareButton, ViberShareButton, WhatsappShareButton } from 'react-share'

import Facebook from './Icons/facebook.svg'
import Twitter from './Icons/twitter.svg'
import Viber from './Icons/viber.svg'
import WhatsApp from './Icons/whatsapp.svg'
import Email from './Icons/gmail.svg'
import Tumblr from './Icons/tumblr.svg'
import Reddit from './Icons/reddit.svg'
import Telegram from './Icons/telegram.svg'

export default function Share(props) {
    return (
        <div className='social-share'>
            <TwitterShareButton title={`Explore ${props.title} on Reel`} url={props.url}>
                <img src={Twitter} alt='Twitter' className='social-icon' />
            </TwitterShareButton>

            <FacebookShareButton quote={`Explore ${props.title} on Reel`} url={props.url} >
                <img src={Facebook} alt='Facebook' className='social-icon' />
            </FacebookShareButton>

            <TumblrShareButton title={`Explore ${props.title} on Reel`} url={props.url}>
                <img src={Tumblr} alt='Tumblr' className='social-icon' />
            </TumblrShareButton>

            <RedditShareButton title={`Explore ${props.title} on Reel`} url={props.url}>
                <img src={Reddit} alt='Reddit' className='social-icon' />
            </RedditShareButton>

            <EmailShareButton url={props.url} openShareDialogOnClick={true} subject={`${props.title} on Reel`}>
                <img src={Email} alt='E-mail' className='social-icon' />
            </EmailShareButton>

            <WhatsappShareButton url={props.url} title={`Explore ${props.title} on Reel`}>
                <img src={WhatsApp} alt='WhatsApp' className='social-icon' />
            </WhatsappShareButton>

            <ViberShareButton url={props.url} title={`${props.title} on Reel`}>
                <img src={Viber} alt='WhatsApp' className='social-icon' />
            </ViberShareButton>

            <TelegramShareButton title={`Explore ${props.title} on Reel`} url={props.url}>
                <img src={Telegram} alt='Telegram' className='social-icon' />
            </TelegramShareButton>
        </div>
    )
}